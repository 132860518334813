import React from "react";

const Patreon = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://www.patreon.com/likoxie"
  >
    <img style={{ width: "150px" }} src="/patron.png" alt="patreon" />
  </a>
);

export default Patreon;
