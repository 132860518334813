import React from "react";
import SearchBar from "./SearchBar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1em",
    marginBottom: "3em",
    background: theme.liko.secondary.light
  },
  formItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em"
  }
}));

const CurationContainer = ({
  handleQueryTagChange,
  handleSubmitSearch,
  queryTags,
  tags
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper variant="outlined" className={classes.paper}>
      <form onSubmit={handleSubmitSearch}>
        <div className={classes.formItem}>
          <SearchBar
            handleQueryTagChange={handleQueryTagChange}
            queryTags={queryTags}
            tags={tags}
          />
        </div>
        <div className={classes.formItem}>
          <Button
            type="submit"
            variant="outlined"
            style={{ color: theme.liko.dark }}
          >
            Submit
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default CurationContainer;
