import React from "react";
import { useTheme } from "@material-ui/core/styles";

const PrivacyPolicy = () => {
  const theme = useTheme();
  return (
    <>
      <a
        href={`https://www.iubenda.com/privacy-policy/16174665`}
        className="iubenda-white iubenda-embed"
        title="Privacy Policy"
        style={{ color: theme.liko.white, textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
    </>
  );
};

export default PrivacyPolicy;
