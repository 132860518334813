const orderOptions = [
  {
    category: "published_at",
    order: "DESC",
    label: "Most Recent"
  },
  {
    category: "published_at",
    order: "ASC",
    label: "Least Recent"
  },
  {
    category: "like_count",
    order: "DESC",
    label: "Most Liked"
  },
  {
    category: "view_count",
    order: "DESC",
    label: "Most Viewed"
  },
  {
    category: "comment_count",
    order: "DESC",
    label: "Most Discussed"
  }
];

const findOrderByLabel = (label) => orderOptions.find((o) => o.label === label);

export { orderOptions, findOrderByLabel };
