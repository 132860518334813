import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Linkify from "linkifyjs/react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2em"
  },
  title: {
    color: theme.liko.white
  },
  description: {
    whiteSpace: "pre-line",
    color: theme.liko.white
  },
  link: {
    textDecoration: "none",
    color: theme.liko.primary.light,
    fontWeight: "bold"
  }
}));

const GridVideoDescription = ({ description }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>Description</h3>
      <Linkify
        options={{
          rel: "noopener noreferrer",
          className: classes.link
        }}
      >
        <p className={classes.description}>{description}</p>
      </Linkify>
    </div>
  );
};

export default GridVideoDescription;
