import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Heart from "../Lotties/Heart";

const GridVideoSupport = ({ youtube_video_id }) => {
  const theme = useTheme();

  return (
    <div style={{ padding: "2em" }}>
      <h3 style={{ color: theme.liko.white, textAlign: "center" }}>
        <p>
          Please support the Creator by visiting the{" "}
          <a
            href={`https://www.youtube.com/watch?v=${youtube_video_id}`}
            style={{
              color: theme.liko.white
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Original YouTube Video
          </a>
          .
        </p>
        <p>Like, comment, subscribe and share!</p>
        <Heart />
        <small>Baked-in features coming later!</small>
      </h3>
    </div>
  );
};

export default GridVideoSupport;
