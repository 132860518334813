import slugify from "slugify";

const slugifyMore = (str) => {
  str = str
    .toLowerCase()
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return slugify(str);
};

export default slugifyMore;
