const classOptions = [
  { label: "Any", value: "Any" },
  { label: "Nightblade", value: "Nightblade" },
  { label: "Templar", value: "Templar" },
  { label: "Sorcerer", value: "Sorcerer" },
  { label: "Warden", value: "Warden" },
  { label: "Dragonknight", value: "Dragonknight" },
  { label: "Necromancer", value: "Necromancer" }
];

const roleOptions = [
  { label: "Any", title: "All Roles", tagId: 894 },
  { label: "Magicka DD", title: "Magicka DDs", tagId: 899 },
  { label: "Stamina DD", title: "Stamina DDs", tagId: 898 },
  { label: "Tank", title: "Tanks", tagId: 895 },
  { label: "Healer", title: "Healers", tagId: 896 }
];

const roleClassOptions = {
  Any: [
    { tagId: 894, label: "Any", title: "Any Role" },
    { tagId: 899, label: "Magicka DD", title: "Any Magicka DD" },
    { tagId: 898, label: "Stamina DD", title: "Any Stamina DD" },
    { tagId: 895, label: "Tank", title: "Any Tank" },
    { tagId: 896, label: "Healer", title: "Any Healer" }
  ],
  Nightblade: [
    { tagId: [693, 687, 699, 705], label: "Any", title: "Any Nightblade" },
    { tagId: 693, label: "Magicka DD", title: "Magicka Nightblade" },
    { tagId: 687, label: "Stamina DD", title: "Stamina Nightblade" },
    { tagId: 699, label: "Tank", title: "Nightblade Tank" },
    { tagId: 705, label: "Healer", title: "Nightblade Healer" }
  ],
  Templar: [
    { tagId: [694, 688, 700, 706], label: "Any", title: "Any Templar" },
    { tagId: 694, label: "Magicka DD", title: "Magicka Templar" },
    { tagId: 688, label: "Stamina DD", title: "Stamina Templar" },
    { tagId: 700, label: "Tank", title: "Templar Tank" },
    { tagId: 706, label: "Healer", title: "Templar Healer" }
  ],
  Sorcerer: [
    { tagId: [695, 689, 701, 707], label: "Any", title: "Any Sorcerer" },
    { tagId: 695, label: "Magicka DD", title: "Magicka Sorcerer" },
    { tagId: 689, label: "Stamina DD", title: "Stamina Sorcerer" },
    { tagId: 701, label: "Tank", title: "Sorcerer Tank" },
    { tagId: 707, label: "Healer", title: "Sorcerer Healer" }
  ],
  Warden: [
    { tagId: [697, 691, 703, 709], label: "Any", title: "Any Warden" },
    { tagId: 697, label: "Magicka DD", title: "Magicka Warden" },
    { tagId: 691, label: "Stamina DD", title: "Stamina Warden" },
    { tagId: 703, label: "Tank", title: "Warden Tank" },
    { tagId: 709, label: "Healer", title: "Warden Healer" }
  ],
  Dragonknight: [
    { tagId: [696, 690, 702, 708], label: "Any", title: "Any Dragonknight" },
    {
      tagId: 696,
      label: "Magicka DD",
      title: "Magicka Dragonknight"
    },
    {
      tagId: 690,
      label: "Stamina DD",
      title: "Stamina Dragonknight"
    },
    { tagId: 702, label: "Tank", title: "Dragonknight Tank" },
    { tagId: 708, label: "Healer", title: "Dragonknight Healer" }
  ],
  Necromancer: [
    { tagId: [698, 692, 704, 710], label: "Any", title: "Any Necromancer" },
    {
      tagId: 698,
      label: "Magicka DD",
      title: "Magicka Necromancer"
    },
    {
      tagId: 692,
      label: "Stamina DD",
      title: "Stamina Necromancer"
    },
    { tagId: 704, label: "Tank", title: "Necromancer Tank" },
    { tagId: 710, label: "Healer", title: "Necromancer Healer" }
  ]
};

const findRoleByLabel = (label) => roleOptions.find((o) => o.label === label);

const findClassByLabel = (label) => classOptions.find((o) => o.label === label);

export {
  classOptions,
  findClassByLabel,
  findRoleByLabel,
  roleOptions,
  roleClassOptions
};
