import React from "react";
import Build from "./Lotties/Build";
import { useTheme } from "@material-ui/core/styles";
import GridTagGroup from "./GridDisplay/GridTagGroup";
import Button from "./Universal/Button";
import { Link } from "react-router-dom";
import { formTagQueryURL } from "./utils/queries";

const SearchHelp = ({ tags }) => {
  const theme = useTheme();

  const searchExamples = [
    {
      title: "All Trial Content",
      tags: ["Trial: Any"]
    },
    {
      title: "All the latest from Kyne's Aegis in Stonethorn",
      tags: ["Patch: Stonethorn", "Trial: Kyne's Aegis"]
    },
    {
      title: "All Magicka Nightblade POVs from Asylum Sanctorium",
      tags: ["Asylum Sanctorium: Full Run", "Role: Magicka Nightblade"]
    },
    {
      title: "All Sunspire Fights from Magicka DDs from PC EU",
      tags: ["Trial: Sunspire", "Role: Any Magicka DD", "Server: PC EU"]
    },
    {
      title:
        "All Rakkhat and Yolnahkriin Fights involving Magicka Dragonknights from the Guilds 4D, Stress Tested and Unchained Animals",
      tags: [
        "Maw of Lorkhaj: Rakkhat",
        "Sunspire: Yolnahkriin",
        "Role: Magicka Dragonknight",
        "Guild: 4D",
        "Guild: Stress Tested",
        "Guild: Unchained Animals"
      ]
    }
  ];

  const findTagByShortPath = (tags, short_path) => {
    return tags.find((t) => t.short_path === short_path);
  };

  const generateTagLink = (tagLabels) => {
    const filteredTags = tagLabels.map((tagLabel) =>
      findTagByShortPath(tags, tagLabel)
    );
    return `/search?${formTagQueryURL(filteredTags)}`;
  };

  return (
    <div style={{ textAlign: "center", paddingBottom: "3em" }}>
      <h2 style={{ color: theme.liko.white }}>
        Example Searches (from Simple to Complex):
      </h2>
      <Build />
      {tags.length > 0 && (
        <>
          {searchExamples.map((example) => {
            return (
              <div key={example.title}>
                <h3 style={{ color: theme.liko.white }}>{example.title}:</h3>
                <div>
                  <GridTagGroup
                    tags={example.tags.map((tag) =>
                      findTagByShortPath(tags, tag)
                    )}
                  />
                  <Link
                    style={{ textDecoration: "none" }}
                    to={generateTagLink(example.tags)}
                  >
                    <Button label="Try Me!" />
                  </Link>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SearchHelp;
