import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EsoSkill from "./EsoSkill";
import RaisedCard from "../Universal/RaisedCard";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center"
  }
});

const EsoSkillCard = ({ skill, description }) => {
  const classes = useStyles();
  return (
    <Grid item md={6} xs={12}>
      <RaisedCard>
        <Grid container className={classes.container}>
          <Grid container item xs={2}>
            <EsoSkill skill={skill} />
          </Grid>
          <Grid container item style={{ justifyContent: "center" }} xs={10}>
            <h4>{skill.name}</h4>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container item style={{ justifyContent: "center" }} xs={12}>
            <p>{description}</p>
          </Grid>
        </Grid>
      </RaisedCard>
    </Grid>
  );
};

export default EsoSkillCard;
