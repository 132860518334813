import React, { useState, useEffect } from "react";
import SearchHelp from "../SearchHelp";
import setMetadata from "../utils/metadata.js";
import { processTags } from "../utils/queries";

const HelpPage = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const loadTags = async () => {
      const tags = await processTags();
      setTags(tags);
    };
    loadTags();
  }, []);
  return (
    <>
      {setMetadata({
        title: "Help | Liko.gg",
        description: "Helping you get started with Liko.gg.",
        url: "https://www.liko.gg/help"
      })}
      <SearchHelp tags={tags} />
    </>
  );
};

export default HelpPage;
