import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tag from "../Universal/Tag";

const useStyles = makeStyles((theme) => ({
  chip: {
    color: theme.liko.white
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  }
}));

const GridTagGroup = ({ tags }) => {
  const classes = useStyles();

  const mapTags = () =>
    tags.map((tag) => <Tag key={tag.id} tag={tag} />);

  return (
    <Box className={classes.container} p={0.5}>
      {mapTags()}
    </Box>
  );
};

export default GridTagGroup;
