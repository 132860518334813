import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  selectDiv: {
    width: "100%"
  },
  select: {
    width: "100%",
    color: theme.liko.white,
    padding: "4px",
    backgroundColor: theme.liko.primary.dark
  },
  selectLabel: {
    color: theme.liko.white
  }
}));

const Selector = ({
  options,
  handleChange,
  selected,
  inputLabel,
  gridWidth
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      lg={gridWidth}
      md={gridWidth}
      sm={12}
      xs={12}
      className={classes.selectDiv}
    >
      <InputLabel className={classes.selectLabel} shrink id="order-label">
        {inputLabel}
      </InputLabel>
      <Select
        className={classes.select}
        labelId="order"
        id="order"
        value={selected.label}
        onChange={handleChange}
      >
        {options.map((o, index) => {
          return (
            <MenuItem key={index} value={o.label}>
              {o.label}
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
};

export default Selector;
