import React, { useReducer } from "react";
import { reducer, init } from "./reducers/reducer";

export const StateContext = React.createContext(null);

const StateProvider = ({ children }) => {
  const [videoState, dispatch] = useReducer(reducer, [], init);

  return (
    <StateContext.Provider value={{ videoState, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
