export const init = (initial) => {
  return {
    videos: initial,
    sets: initial,
    skills: initial,
    suggestions: initial
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_SETS":
      return { ...state, sets: action.payload };
    case "ADD_SKILLS":
      return { ...state, skills: action.payload };
    case "REPLACE":
      return {
        ...state,
        videos: action.payload
      };
    case "APPEND":
      return {
        ...state,
        videos: [...state.videos, ...action.payload]
      };
    case "SUGGEST":
      return {
        ...state,
        suggestions: action.payload
      };
    case "reset":
      return init(action.payload);
    default:
      throw new Error();
  }
};
