import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Heart from "../Lotties/Heart";
import { makeStyles } from "@material-ui/core/styles";
import PayPalDonate from "../Universal/PayPalDonate";
import Patreon from "../Universal/Patreon";
import setMetadata from "../utils/metadata";
import VideoPlayer from "../Video/VideoPlayer";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.liko.secondary.main,
    padding: "1em",
    marginBottom: "3em",
    color: theme.liko.white
  },
  container: {
    paddingBottom: "5em",
    color: theme.liko.white
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const contributors = [
  "Brad",
  "Dkfusion",
  "OwnLight",
  "AverageJoe316",
  "Paratomick",
  "Draveus",
  "youth",
  "Oreyn_Bearclaw",
  "Al",
  "NefasQS",
  "Meh",
  "hikarichan",
  "TheRoyalPlayer",
  "Lady Yousha",
  "stileanima",
  "Skinny Cheeks",
  "MrPrecise",
  "Meredith"
].sort();

const AboutPage = () => {
  const classes = useStyles();

  const announcementId = "oNaWkXWYUY0";
  return (
    <Container maxWidth="md" className={classes.container}>
      {setMetadata({
        title: "About | Liko.gg",
        description: "About Liko.gg.",
        url: "https://www.liko.gg/about"
      })}
      <h1>About</h1>
      <h2>Message from Liko</h2>
      <Paper elevation={4} className={classes.paper}>
        <p>
          <strong>Liko.gg</strong> is my little side-project that I've built
          from scratch and have been working on it since early 2020. One thing
          that I've always known, is how much I like to help others improve, and
          the reciprocal benefits of doing so.
        </p>
        <p>
          In the last year or two, I've created some content on YouTube which I
          hope has helped a few out there improve in Elder Scrolls Online as a
          Damage Dealer.
        </p>
        <p>
          Figuring out the different classes for each patch has been fun, but
          the actual video-editing isn't necessarily my favourite part and as a
          result I don't see it as scalable/maintainable long-term, and as
          you've seen in more recent iterations, I've teamed up with other
          community members to help freshen things up.
        </p>
        <p>
          Liko.gg is a platform that hopes to promote and preserve a more
          community-centric approach to sharing PVE-related knowledge, strats
          and milestones.
        </p>
      </Paper>

      <h2>Help Out</h2>

      <Paper elevation={4} className={classes.paper}>
        <p>
          Liko.gg is currently run out of my own pocket. There is a cost to
          maintaining the bits you see on this website, as well as everything
          happening in the background. I've done as much as I can to keep my
          costs to a minimum thus far, but I also want to provide the best
          experience possible for everyone!
        </p>
        <p>
          If you like what I'm doing here, and/or if you found my YouTube
          content helpful in the past, any donations are massively appreciated!
        </p>

        <div className={classes.center}>
          <Patreon />
        </div>
        <br />
        <div className={classes.center}>
          <PayPalDonate />
        </div>
        <div className={classes.center}>
          <p>Thank You!</p>
        </div>
      </Paper>

      <Heart />
      <Paper elevation={4} className={classes.paper}>
        <div className={classes.center}>
          <h3>Thanks to the following for their support:</h3>
        </div>

        <div className={classes.center}>
          <ul>
            {contributors.map((c, i) => (
              <li key={i}>{c}</li>
            ))}
          </ul>
        </div>

        <div className={classes.center}>
          <p>And everyone else!</p>
        </div>
      </Paper>

      <Heart />

      <h2>Questions and Answers</h2>

      <Paper elevation={4} className={classes.paper}>
        <h3>Why Liko.gg?</h3>
        <p>
          The Elder Scrolls Online has a great PVE community, where there are a
          large number of Guilds/Communities across several different
          Platforms/Regions that focus on Trials and Dungeons.
        </p>

        <p>
          One thing that has been missing until this point, is a good way to
          search for specific high quality Elder Scrolls Online video content.
          Every video that you see here on Liko.gg has been specifically
          curated, to form ESO-specific categories and filters that YouTube only
          pretends to understand.
        </p>
      </Paper>

      <Paper elevation={4} className={classes.paper}>
        <h3>
          How can Liko.gg benefit me as somone who likes to create/share videos?
        </h3>
        <p>
          Unless you understand and cater towards the YouTube algorithm, YouTube
          won't do a great job in promoting content. This makes it hard to
          receive organic views. For many of us, this isn't a big deal, but if
          you've already put in the effort making a video, then it's a bonus if
          it helps others too! Over time, Liko.gg will be a good place for your
          content to be discovered if you let us know about your content!
        </p>
      </Paper>

      <Paper elevation={4} className={classes.paper}>
        <h3>How can Liko.gg benefit me as an ESO Player?</h3>
        <p>
          Well, hopefully you won't spend ages trying to find exactly what
          you're looking for. Liko.gg should be a great place to discover how
          others successfully play certain classes/roles in different situations
          - or how a boss fight has changed over time compared to this time last
          year!
        </p>
      </Paper>

      <Paper elevation={4} className={classes.paper}>
        <h3>
          I didn't find the content that I was looking for! Why isn't Video X or
          Channel Y included?
        </h3>
        <p>
          We are adding new videos all the time! Consistently, this will include
          new videos from creators as they are released. But over time, older
          videos from more consistent creators will be added to the catalogue!
        </p>

        <p>The main reasons to why you couldn't find something are:</p>
        <ul>
          <li>Currently difficult to categorise</li>
          <li>
            Less recent video, so some extra excavation will be done in due time
          </li>
          <li>
            We haven't discovered this content yet (give us a shout on Discord @
            liko#8888!)
          </li>
          <li>We forgot, sorry!</li>
        </ul>
      </Paper>
      <Paper elevation={4} className={classes.paper}>
        <h3>I have some ideas / suggestions. How can I get involved?</h3>
        <p>
          Sounds good. Feel free to message me on Discord @ liko#8888 or via
          email: liko@liko.gg!
        </p>
      </Paper>
      <Paper elevation={4} className={classes.paper}>
        <h3>
          I want to suggest a Guild / YouTube Channel / Video for curation. How
          can I let you know?
        </h3>
        <p>
          Feel free to message me on Discord @ liko#8888, or drop me an email:
          liko@liko.gg! Give me all the details!
        </p>
      </Paper>
      <Heart />

      <h2>Announcement Video: 15th May, 2020</h2>

      <VideoPlayer videoId={announcementId} />
    </Container>
  );
};

export default AboutPage;
