import React from "react";
import SearchSad from "../Lotties/SearchSad";

const ErrorPage = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}
  >
    <SearchSad />
  </div>
);

export default ErrorPage;
