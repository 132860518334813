const trialOptions = [
  {
    label: "Any",
    title: "Any Trial",
    tagId: 734
  },
  {
    label: "Rockgrove",
    title: "Rockgrove",
    tagId: 1043
  },
  {
    label: "Kyne's Aegis",
    title: "Kyne's Aegis",
    tagId: 925
  },
  {
    label: "Sunspire",
    title: "Sunspire",
    tagId: 762
  },
  {
    label: "Cloudrest",
    title: "Cloudrest",
    tagId: 743
  },
  {
    label: "Asylum Sanctorium",
    title: "Asylum Sanctorium",
    tagId: 741
  },
  {
    label: "The Halls of Fabrication",
    title: "The Halls of Fabrication",
    tagId: 767
  },
  {
    label: "Maw of Lorkhaj",
    title: "Maw of Lorkhaj",
    tagId: 751
  },
  {
    label: "Sanctum Ophidia",
    title: "Sanctum Ophidia",
    tagId: 756
  },
  {
    label: "Aetherian Archive",
    title: "Aetherian Archive",
    tagId: 735
  },
  {
    label: "Hel Ra Citadel",
    title: "Hel Ra Citadel",
    tagId: 745
  }
];

const findTrialByLabel = (label) => trialOptions.find((o) => o.label === label);

export { trialOptions, findTrialByLabel };
