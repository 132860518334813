import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import VideoPlayer from "../Video/VideoPlayer";
import GridVideoDetailedCard from "../GridDisplay/GridVideoDetailedCard";
import GridVideoShowCard from "../GridDisplay/GridVideoShowCard";
import GridVideoSupport from "../GridDisplay/GridVideoSupport";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { StateContext } from "../StateProvider";
import { StickyContainer, Sticky } from "react-sticky";
import PostContainer from "../Posts/PostContainer";
import { fetchResource } from "../utils/queries";
import setMetadata from "../utils/metadata.js";
import slugify from "../utils/slugify";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";

const useStyles = makeStyles((theme) => ({
  infoPaper: { padding: "1em", margin: "1vh 0vh" },
  videoPaper: {
    padding: "0.3em",
    margin: "1em 0",
    background: theme.liko.secondary.main
  },
  zIndex: {
    zIndex: 100000
  }
}));

const ContentPage = ({ link }) => {
  let { videoState, dispatch } = useContext(StateContext);
  const video = videoState.videos[0];
  const classes = useStyles();
  const history = useHistory();

  let { id, slug } = useParams();

  const [isFetching, setIsFetching] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const [helmetState, setHelmetState] = useState({
    title: "Content | Liko.gg",
    description: `Enjoy this content on Liko.gg`,
    url: `https://www.liko.gg/content/${id}`
  });

  useEffect(() => {
    const fetchAndDispatchContent = async () => {
      try {
        const payload = await fetchResource(`videos/${id}`);
        dispatch({ type: "REPLACE", payload });
        setHelmetState({
          title: `${payload[0].title} by ${payload[0].channel.title} | Liko.gg`,
          description: `Enjoy this content on Liko.gg. ${payload[0].description}`,
          image: `${payload[0].thumbnail_default}`
        });
        setIsFetching(false);
        setIsReady(true);

        const url = `/content/${payload[0].id}/${slugify(
          payload[0].channel.title
        )}-${slugify(payload[0].title)}`;

        history.replace(url);
      } catch (err) {
        setIsFetching(false);
      }
    };
    fetchAndDispatchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slug]);

  const whatToRender = () => {
    if (isFetching) {
      return <LoadingPage />;
    }

    if (isReady) {
      return (
        <StickyContainer>
          <Sticky topOffset={-64}>
            {({ style }) => (
              <div className={classes.zIndex} style={style}>
                <Paper
                  elevation={5}
                  square={false}
                  className={classes.videoPaper}
                >
                  <VideoPlayer videoId={video.youtube_video_id} />
                </Paper>
              </div>
            )}
          </Sticky>

          <GridVideoDetailedCard
            className={classes.videoPaper}
            link={link}
            {...video}
          />
          <Paper elevation={5} square={false} className={classes.videoPaper}>
            <GridVideoSupport {...video} />
          </Paper>
          <GridVideoShowCard className={classes.videoPaper} {...video} />
          {video.channel.title === "Liko" && (
            <PostContainer youtube_video_id={video.youtube_video_id} />
          )}
          {/* {mapSuggestions(videoState.suggestions)} */}
        </StickyContainer>
      );
    } else {
      return <ErrorPage />;
    }
  };

  return (
    <Fragment>
      {setMetadata({
        title: helmetState.title,
        description: helmetState.description,
        url: helmetState.url,
        image: helmetState.image
      })}
      {whatToRender()}
    </Fragment>
  );
};

export default ContentPage;
