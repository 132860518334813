import React, { useContext, useEffect, useState } from "react";
import {
  classOptions,
  findClassByLabel,
  findRoleByLabel,
  roleClassOptions,
  roleOptions
} from "./roleClassOptions";
import { findOrderByLabel, orderOptions } from "../../utils/orderOptions";
import { findPatchByLabel, patchOptions } from "./patchOptions";
import { findTrialByLabel, trialOptions } from "./trialOptions";
import { formTagQueryURLByIds, fetchResource } from "../../utils/queries";
import { makeStyles } from "@material-ui/core/styles";
import { StateContext } from "../../StateProvider";
import appendVideo from "../../utils/appendVideo";
import ErrorPage from "../ErrorPage";
import Grid from "@material-ui/core/Grid";
import GridVideoPreviewContainer from "../../GridDisplay/GridVideoPreviewContainer";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../Lotties/Loader";
import LoadingPage from "../LoadingPage";
import NoResultsFilter from "../../NoResultsFilter";
import Selector from "../../Form/Selector";
import setMetadata from "../../utils/metadata";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.liko.white
  },
  title: {
    color: theme.liko.white
  },
  link: {
    color: theme.liko.white,
    textDecoration: "none"
  }
}));

const TrialsPage = ({ link }) => {
  let { videoState, dispatch } = useContext(StateContext);

  const classes = useStyles();

  const [selectedOrder, setSelectedOrder] = useState(
    findOrderByLabel("Most Recent")
  );
  const [selectedPatch, setSelectedPatch] = useState(findPatchByLabel("Any"));
  const [selectedRole, setSelectedRole] = useState(findRoleByLabel("Any"));
  const [selectedClass, setSelectedClass] = useState(findClassByLabel("Any"));
  const [selectedTrial, setSelectedTrial] = useState(findTrialByLabel("Any"));

  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loading, setLoading] = useState(false);

  const gridWidth = 4;

  const status = {
    page,
    setPage,
    hasMoreItems,
    setHasMoreItems,
    loading,
    setLoading
  };

  const handleClassChange = (event) => {
    setSelectedClass(findClassByLabel(event.target.value));
  };

  const handleRoleChange = (event) => {
    setSelectedRole(findRoleByLabel(event.target.value));
  };

  const handleOrderChange = (event) => {
    setSelectedOrder(findOrderByLabel(event.target.value));
  };

  const handlePatchChange = (event) => {
    setSelectedPatch(findPatchByLabel(event.target.value));
  };

  const handleTrialChange = (event) => {
    setSelectedTrial(findTrialByLabel(event.target.value));
  };

  const [isFetching, setIsFetching] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const deriveRoleId = () =>
    roleClassOptions[selectedClass.label].find(
      (role) => role.label === selectedRole.label
    ).tagId;

  const deriveRoleTitle = () =>
    roleClassOptions[selectedClass.label].find(
      (role) => role.label === selectedRole.label
    ).title;

  const tagIds = [
    deriveRoleId(),
    selectedPatch.tagId,
    selectedTrial.tagId
  ].flat();

  const url = `videos?is_curated=true&per_page=12&order_by[order]=${
    selectedOrder.order
  }&order_by[category]=${selectedOrder.category}&${formTagQueryURLByIds(
    tagIds
  )}`;

  const appendingUrl = `videos?is_curated=true&page=${
    parseInt(page) + 1
  }&per_page=12&order_by[order]=${selectedOrder.order}&order_by[category]=${
    selectedOrder.category
  }&${formTagQueryURLByIds(tagIds)}`;

  useEffect(() => {
    const fetchAndDispatchContent = async () => {
      try {
        setHasMoreItems(true);
        setPage(1);
        const payload = await fetchResource(url);

        dispatch({ type: "REPLACE", payload });
        setIsFetching(false);
        setIsReady(true);
      } catch (err) {
        setIsFetching(false);
      }
    };
    fetchAndDispatchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRole,
    selectedClass,
    selectedPatch,
    selectedOrder,
    selectedTrial
  ]);

  const whatToRender = () => {
    if (isFetching) {
      return <LoadingPage />;
    }

    if (isReady) {
      return mapSections();
    } else {
      return <ErrorPage />;
    }
  };

  const mapSections = () => {
    return (
      <>
        <div className={classes.container}>
          <h2>{`${selectedOrder.label} footage from ${deriveRoleTitle()}s in ${
            selectedTrial.title
          } during ${selectedPatch.title}`}</h2>
        </div>
        <InfiniteScroll
          pageStart={page}
          loadMore={() => appendVideo(appendingUrl, status, dispatch)}
          initialLoad={false}
          hasMore={hasMoreItems}
          loader={<Loader />}
        >
          {videoState.videos.length ? (
            <GridVideoPreviewContainer link={link} />
          ) : (
            <NoResultsFilter />
          )}
        </InfiniteScroll>
      </>
    );
  };

  return (
    <>
      {setMetadata({
        title: "Trials | Liko.gg",
        description: "12-Man Trials Footage for the Elder Scrolls Online.",
        url: "https://www.liko.gg/trials"
      })}
      <div className={classes.title}>
        <h1>Trials</h1>
        <h3>
          This is a collection of the community's Elder Scrolls Online PVE
          Trials Footage.
        </h3>
        <h4>
          Filter by Trial (e.g. Kyne's Aegis), Role (e.g. Magicka Damage
          Dealer), Class (e.g. Nightblade) or relevance towards a particular DLC
          (e.g. Stonethorn).
        </h4>
        <br />
      </div>

      <Grid container spacing={3} className={classes.selectContainer}>
        <Selector
          options={orderOptions}
          handleChange={handleOrderChange}
          selected={selectedOrder}
          inputLabel="Order By"
          gridWidth={gridWidth}
        />
        <Selector
          options={trialOptions}
          handleChange={handleTrialChange}
          selected={selectedTrial}
          inputLabel="By Trial"
          gridWidth={gridWidth}
        />
        <Selector
          options={roleOptions}
          handleChange={handleRoleChange}
          selected={selectedRole}
          inputLabel="By Role"
          gridWidth={gridWidth}
        />
        <Selector
          options={classOptions}
          handleChange={handleClassChange}
          selected={selectedClass}
          inputLabel="By Class"
          gridWidth={gridWidth}
        />
        <Selector
          options={patchOptions}
          handleChange={handlePatchChange}
          selected={selectedPatch}
          inputLabel="By Patch"
          gridWidth={gridWidth}
        />
      </Grid>
      {whatToRender()}
    </>
  );
};

export default TrialsPage;
