import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  raisedCard: {
    padding: "1em",
    margin: "1vh 1vh",
    height: "100%",
    background: theme.liko.secondary.light
  }
}));

const RaisedCard = ({ children }) => {
  const [raised, setRaised] = useState(false);

  const toggleRaised = () => {
    setRaised(!raised);
  };

  const classes = useStyles();
  return (
    <Card
      onMouseOver={toggleRaised}
      onMouseOut={toggleRaised}
      raised={raised}
      className={classes.raisedCard}
    >
      {children}
    </Card>
  );
};

export default RaisedCard;
