import React, { Fragment } from "react";
import Switch from "./Switch";

const SwitchForm = ({ switches }) => {
  return (
    <Fragment>
      {switches.map(s => (
        <Switch
          key={s.handle}
          handleChange={s.handle}
          isChecked={s.state}
          label={s.label}
        />
      ))}
    </Fragment>
  );
};

export default SwitchForm;
