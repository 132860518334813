import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoId }) => {
  return (
    <ReactPlayer
      style={{ maxHeight: "60vh" }}
      url={`https://www.youtube.com/watch?v=${videoId}`}
      controls
      playing={false}
      width="100%"
      height={window.innerWidth / 1.9}
    />
  );
};

export default VideoPlayer;
