import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FavoriteIcon from "@material-ui/icons/Favorite";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CommentIcon from "@material-ui/icons/Comment";
import { useTheme } from "@material-ui/core/styles";
import {
  prettyLikeColor,
  prettyViewCount,
  prettyLikeRatio
} from "../utils/prettyStats";

const GridVideoStats = ({ videoStats }) => {
  const theme = useTheme();

  const { comment_count, like_count, dislike_count, view_count } = videoStats;

  const likeRatio = prettyLikeRatio(like_count, dislike_count);

  const stats = [
    {
      label: "likeRatio",
      count: `${likeRatio}${likeRatio > 0 ? "%" : ""}`,
      icon: <FavoriteIcon style={{ color: theme.liko.primary.main }} />,
      color: theme.liko.rating.dark[prettyLikeColor(likeRatio)]
    },
    {
      label: "viewCount",
      count: prettyViewCount(view_count),
      icon: <VisibilityIcon style={{ color: theme.liko.white }} />
    },
    {
      label: "commentCount",
      count: comment_count,
      icon: <CommentIcon style={{ color: theme.liko.white }} />
    }
  ];

  return (
    <Box
      p={0.5}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        margin: "auto",
        maxWidth: "800px"
      }}
    >
      {stats.map((stat) => (
        <Typography
          key={stat.label}
          gutterBottom
          variant="body1"
          component="h2"
          noWrap={false}
        >
          <div>{stat.icon}</div>
          <div
            style={{
              textAlign: "center",
              color: stat.color || theme.liko.white
            }}
          >
            <strong>{stat.count}</strong>
          </div>
        </Typography>
      ))}
    </Box>
  );
};

export default GridVideoStats;
