import React from "react";
import RaisedCard from "../Universal/RaisedCard";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";

const EsoSet = ({ set }) => {
  const theme = useTheme();
  const bonuses = [
    set.bonus_item_1,
    set.bonus_item_2,
    set.bonus_item_3,
    set.bonus_item_4,
    set.bonus_item_5
  ];

  const mapSetBonuses = (bonuses) => {
    return bonuses.map((b, i) => {
      return (
        b !== null && (
          <p style={{ textAlign: "center" }} key={i}>
            <strong>{`(${i + 1} piece)`}</strong> - {b}
          </p>
        )
      );
    });
  };

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <RaisedCard>
        <a
          style={{ textDecoration: "none", color: theme.liko.secondary.dark }}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://eso-sets.com/set/${set.slug}`}
        >
          <h2 style={{ textAlign: "center" }}>{set.name}</h2>
        </a>
        <Divider variant="middle" />
        <h3 style={{ textAlign: "center" }}>Type: {set.set_type}</h3>
        {mapSetBonuses(bonuses)}
        <Divider variant="middle" />
      </RaisedCard>
    </Grid>
  );
};

export default EsoSet;
