import React, { useContext, useEffect } from "react";
import Paper from "@material-ui/core/Card";
import { fetchResource } from "../utils/queries";
import { StateContext } from "../StateProvider";
import sL from "../esoReferences/skills";
import sG from "../esoReferences/sets";
import postRouter from "./postRouter";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  infoPaper: {
    padding: "1em",
    margin: "1vh 0vh",
    background: theme.liko.secondary.main
  }
}));

const PostContainer = ({ youtube_video_id }) => {
  let { videoState, dispatch } = useContext(StateContext);
  let { sets, skills } = videoState;
  const classes = useStyles();

  useEffect(() => {
    findEsoData(youtube_video_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findEsoData = async (id) => {
    if (postRouter[id] && postRouter[id].type === "magicka") {
      await handleSets(sG.magicka);
      await handleSkills({
        ...sL.sorcerer,
        ...sL.destructiveStaff,
        ...sL.soulMagic,
        ...sL.magesGuild,
        ...sL.fightersGuild,
        ...sL.psijicGuild
      });
    }
  };

  const handleSkills = async (skills) => {
    const skillIds = Object.values(skills);
    const payload = await fetchSkillsByIds(skillIds);
    dispatch({ type: "ADD_SKILLS", payload });
  };

  const fetchSkillsByIds = async (ids) => {
    return await Promise.all(
      ids.map(async (id) => fetchResource(`eso_skills/${id}`))
    );
  };

  const handleSets = async (sets) => {
    const setIds = Object.values(sets);
    const payload = await fetchSetsByIds(setIds);
    dispatch({ type: "ADD_SETS", payload });
  };

  const fetchSetsByIds = async (ids) => {
    return await Promise.all(
      ids.map(async (id) => fetchResource(`eso_sets/${id}`))
    );
  };

  const findPost = (id) => {
    return postRouter[id] ? (
      <Paper elevation={5} square={false} className={classes.infoPaper}>
        {postRouter[id].component}
      </Paper>
    ) : null;
  };

  return !!sets.length && !!skills.length && findPost(youtube_video_id);
};

export default PostContainer;
