import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import slugify from "../utils/slugify";

const decode = require("unescape");

const GridVideoInfo = ({ id, link, youtube_video_id, title, channel }) => {
  const theme = useTheme();
  title = decode(title);
  const pathname =
    link === "/video" || link === "/curationShow"
      ? `https://www.youtube.com/watch?v=${youtube_video_id}`
      : `${link}/${id}/${slugify(channel.title)}-${slugify(title)}`;

  const getCorrectLink = () => {
    return link === "/video" || link === "/curationShow" ? (
      <a
        style={{ textDecoration: "none", color: theme.liko.white }}
        target="_blank"
        rel="noopener noreferrer"
        href={pathname}
      >
        <strong>{title}</strong>
      </a>
    ) : (
      <Link
        style={{ textDecoration: "none", color: theme.liko.white }}
        to={{
          pathname,
          state: { youtube_video_id: youtube_video_id }
        }}
      >
        <strong>{title}</strong>
      </Link>
    );
  };

  return (
    <Box
      p={0.5}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        margin: "auto",
        maxWidth: "800px"
      }}
    >
      <Typography gutterBottom variant="body1" component="h2" noWrap={false}>
        {getCorrectLink()}
      </Typography>
    </Box>
  );
};

export default GridVideoInfo;
