import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function SwitchLabels({ isChecked, label, handleChange }) {
  return (
    <FormControlLabel
      control={
        <Switch onChange={handleChange} value={isChecked} checked={isChecked} />
      }
      label={label}
    />
  );
}
