import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";

const white = "#F5F5F5";
const gold = "#b26a00";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Noto Sans JP", "sans-serif"].join(",")
  },
  liko: {
    primary: {
      light: red[400],
      main: red[800],
      dark: red[900]
    },
    secondary: {
      light: grey[500],
      main: grey[800],
      dark: grey[900]
    },
    white,
    featured: gold,
    rating: {
      dark: {
        90: "#B2FF59",
        80: "#C6FF00",
        60: "#FFFF00",
        40: "#FFAB40",
        20: "#FF3D00",
        10: "#F44336"
      },
      light: {
        90: "#094B0C",
        80: "#137B18",
        60: "#636C08",
        40: "#9C5D02",
        20: "#9C0202",
        10: "#7D0800"
      }
    }
  }
});

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
