import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import PayPalDonate from "./PayPalDonate";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import BuildIcon from "@material-ui/icons/Build";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import PeopleIcon from "@material-ui/icons/People";
import NaturePeopleIcon from "@material-ui/icons/NaturePeople";
import WidgetsIcon from "@material-ui/icons/Widgets";
import StarsIcon from "@material-ui/icons/Stars";
import MenuIcon from "@material-ui/icons/Menu";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ScrollTop from "./ScrollTop";
import { useAuth } from "react-use-auth";
import PrivacyPolicy from "./PrivacyPolicy";
import Patreon from "./Patreon";

const NavDrawer = ({ drawerWidth }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: theme.liko.primary.dark,
      opacity: 0.99
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    navLink: {
      textDecoration: "none",
      color: theme.liko.white
    },
    authItem: {
      paddingBottom: "1em",
      paddingTop: "1em",
      color: theme.liko.white
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      background: theme.liko.secondary.main
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    navIcon: {
      color: theme.liko.white
    },
    bttButton: {
      backgroundColor: theme.liko.primary.dark,
      color: theme.liko.white
    },
    listItemCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.liko.white
    }
  }));

  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { isAuthenticated, login, logout, user } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [selectedLink, setSelectedLink] = useState(window.location.pathname);

  const handleListItemClick = (event, link) => {
    setSelectedLink(link);
    mobileOpen && handleDrawerToggle();
  };

  const drawer = (
    <Fragment>
      <div>
        <div className={classes.toolbar} />
        <div>
          <Divider />
        </div>
        <Fragment>
          <List>
            {isAuthenticated() && (
              <ListItem className={classes.authItem}>
                <ListItemIcon>
                  <Avatar
                    variant="rounded"
                    className={classes.small}
                    alt="Liko"
                    src={user.picture}
                  />
                </ListItemIcon>

                <ListItemText
                  primary={`Hey ${isAuthenticated() && user.nickname + "!"}`}
                />
              </ListItem>
            )}
            <Divider />
            {[
              {
                label: "Home",
                link: "/",
                icon: <HomeIcon className={classes.navIcon} fontSize="large" />
              },
              {
                label: "From Liko",
                link: "/liko",
                icon: (
                  <Avatar
                    variant="rounded"
                    className={classes.small}
                    alt="Liko"
                    src={
                      "https://yt3.ggpht.com/a/AGF-l7_rD3VpY0qAMf7QoCtqXY0rZYUOmH73OwkTlw=s88-c-k-c0xffffffff-no-rj-mo"
                    }
                  />
                )
              },
              {
                label: "Discover",
                link: "/community",
                icon: (
                  <PeopleIcon className={classes.navIcon} fontSize="large" />
                )
              },
              {
                label: "Builds",
                link: "/builds",
                icon: (
                  <WidgetsIcon className={classes.navIcon} fontSize="large" />
                )
              },
              {
                label: "Trials",
                link: "/trials",
                icon: <StarsIcon className={classes.navIcon} fontSize="large" />
              },
              {
                label: "Arenas",
                link: "/arenas",
                icon: (
                  <NaturePeopleIcon
                    className={classes.navIcon}
                    fontSize="large"
                  />
                )
              },
              {
                label: "Search",
                link: "/search",
                icon: (
                  <SearchIcon className={classes.navIcon} fontSize="large" />
                )
              },
              {
                label: "Help",
                link: "/help",
                icon: (
                  <HelpOutlineIcon
                    className={classes.navIcon}
                    fontSize="large"
                  />
                )
              },
              {
                label: "About",
                link: "/about",
                icon: (
                  <EmojiObjectsIcon
                    className={classes.navIcon}
                    fontSize="large"
                  />
                )
              }
              /* {
              label: "Favourites",
              link: "/community",
              icon: <StarIcon fontSize="large" style={{ color: "#FFC107" }} />
            }, */
            ].map((item, index) => (
              <NavLink
                key={item.label}
                to={item.link}
                className={classes.navLink}
              >
                <ListItem
                  button
                  key={item.label}
                  selected={selectedLink === item.link}
                  onClick={(event) => handleListItemClick(event, item.link)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </NavLink>
            ))}

            {isAuthenticated() &&
              (user.email === "likoxie@gmail.com" ||
                user.email === "stileanima@gmail.com") && (
                <NavLink to="/curation" className={classes.navLink}>
                  <ListItem
                    button
                    key={"Curate"}
                    selected={selectedLink === "/curation"}
                    onClick={(event) => handleListItemClick(event, "/curation")}
                  >
                    <ListItemIcon>
                      <BuildIcon className={classes.navIcon} fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary="Curate" />
                  </ListItem>
                </NavLink>
              )}
            <div>
              <Divider />
            </div>
            {isAuthenticated() && (
              <ListItem button onClick={logout} className={classes.authItem}>
                <ListItemIcon>
                  <ExitToAppIcon className={classes.navIcon} fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </ListItem>
            )}
            {window.location.pathname === "/secret" && !isAuthenticated() && (
              <ListItem button onClick={login} className={classes.authItem}>
                <ListItemIcon>
                  <YouTubeIcon className={classes.navIcon} fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Log In" />
              </ListItem>
            )}
          </List>
        </Fragment>
      </div>
      <div>
        <List>
          <ListItem className={classes.listItemCenter}>
            <Patreon />
          </ListItem>
          <ListItem>
            <PayPalDonate />
          </ListItem>
          <ListItem className={classes.listItemCenter}>
            <div>© 2020 LikoXie</div>
          </ListItem>
          <ListItem className={classes.listItemCenter}>
            <PrivacyPolicy />
          </ListItem>
        </List>
      </div>
    </Fragment>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <NavLink to="/" className={classes.navLink}>
              Liko.gg
            </NavLink>
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <nav className={classes.drawer} aria-label="menu options">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <ScrollTop>
        <Fab
          className={classes.bttButton}
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
};

export default NavDrawer;
