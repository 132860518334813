import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import slugify from "../utils/slugify";
import LinesEllipsis from "react-lines-ellipsis";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import YouTubeIcon from "@material-ui/icons/YouTube";

const decode = require("unescape");

const GridVideoPrimary = ({
  id,
  link,
  youtube_video_id,
  title,
  channel,
  showTags,
  setShowTags
}) => {
  const theme = useTheme();
  title = decode(title);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const likoGgUrl = `${link}/${id}/${slugify(channel.title)}-${slugify(title)}`;
  const youtubeUrl = `https://youtu.be/${youtube_video_id}`;

  const pathname =
    link === "/video" || link === "/curationShow" ? youtubeUrl : likoGgUrl;

  const getCorrectLink = () => {
    return link === "/video" || link === "/curationShow" ? (
      <a
        style={{ textDecoration: "none", color: theme.liko.white }}
        target="_blank"
        rel="noopener noreferrer"
        href={pathname}
      >
        <LinesEllipsis
          text={title}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </a>
    ) : (
      <Link
        style={{ textDecoration: "none", color: theme.liko.white }}
        to={{
          pathname,
          state: { youtube_video_id: youtube_video_id }
        }}
      >
        <LinesEllipsis
          text={title}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </Link>
    );
  };

  const handleShowTags = () => {
    setShowTags(!showTags);
    handleClose();
  };

  const { enqueueSnackbar } = useSnackbar();

  const showToast = () => {
    enqueueSnackbar("Copied to Clipboard!", {
      variant: "success",
      autoHideDuration: 3000
    });
    handleClose();
  };

  return (
    <Box
      p={0.5}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        margin: "auto",
        maxWidth: "800px",
        alignItems: "center"
      }}
    >
      <Box pr={1}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.youtube.com/channel/${channel.youtube_channel_id}`}
        >
          <Avatar alt={decode(channel.title)} src={channel.thumbnail_default} />
        </a>
      </Box>
      <Box>
        <Typography variant="body2" noWrap={false}>
          {getCorrectLink()}
        </Typography>
      </Box>
      <Box style={{ marginLeft: "auto" }}>
        <IconButton onClick={handleClick} style={{ padding: 0 }}>
          <MoreVertIcon style={{ color: theme.liko.white }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleShowTags}>
            <ListItemIcon>
              <LoyaltyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={`${showTags ? "Hide" : "Show"} Tags`} />
          </MenuItem>
          <CopyToClipboard text={youtubeUrl} onCopy={showToast}>
            <MenuItem>
              <ListItemIcon>
                <YouTubeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Copy YouTube Link" />
            </MenuItem>
          </CopyToClipboard>

          <CopyToClipboard
            text={`${window.location.origin}${likoGgUrl}`}
            onCopy={showToast}
          >
            <MenuItem>
              <ListItemIcon>
                <FileCopyOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Copy Liko.gg Link" />
            </MenuItem>
          </CopyToClipboard>
        </Menu>
      </Box>
    </Box>
  );
};

export default GridVideoPrimary;
