import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import EsoSkill from "./EsoSkill";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center"
  }
});

const EsoSkillBar = ({ skills }) => {
  const classes = useStyles();

  const mapSkills = skills => {
    return skills.map((s, i) => (
      <Grid item key={i} xs={2}>
        <EsoSkill skill={s} />{" "}
      </Grid>
    ));
  };

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item md={6} xs={12}>
        <h4>Frontbar</h4>
        <Grid container className={classes.container} spacing={1}>
          {mapSkills(skills.slice(0, 6))}
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <h4>Backbar</h4>
        <Grid container className={classes.container} spacing={1}>
          {mapSkills(skills.slice(6))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EsoSkillBar;
