import React, { useState, useEffect, useContext, Fragment } from "react";
import GridVideoDetailedContainer from "../GridDisplay/GridVideoDetailedContainer";
import SearchContainer from "../Form/SearchContainer";
import SwitchForm from "../Form/SwitchForm";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { fetchResource, formTagQueryURL, processTags } from "../utils/queries";
import InfiniteScroll from "react-infinite-scroller";
import { StateContext } from "../StateProvider";
import appendVideo from "../utils/appendVideo";
import handleSubmitSearch from "../utils/handleSubmitSearch";
import Loader from "../Lotties/Loader";
import { findOrderByLabel, orderOptions } from "../utils/orderOptions";
import NoResults from "../NoResults";
import setMetadata from "../utils/metadata.js";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1em",
    padding: "1em",
    background: theme.liko.secondary.light
  }
}));

const CurationPage = ({ link }) => {
  const classes = useStyles();
  let { videoState, dispatch } = useContext(StateContext);

  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loading, setLoading] = useState(false);

  const status = {
    page,
    setPage,
    hasMoreItems,
    setHasMoreItems,
    loading,
    setLoading
  };

  const [query, setQuery] = useState([]);

  const [selectedOption, setSelectedOption] = useState(
    findOrderByLabel("Most Recent")
  );

  const handleQueryTagChange = (event, value) => {
    setQuery(value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(findOrderByLabel(event.target.value));
  };

  const [isFetching, setIsFetching] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const [isCurated, setIsCurated] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const [tags, setTags] = useState([]);

  const handleToggleCurated = () => {
    setIsCurated(!isCurated);
  };

  const handleToggleFeatured = () => {
    setIsFeatured(!isFeatured);
  };

  const handleToggleHidden = () => {
    setIsHidden(!isHidden);
  };

  const switches = [
    { state: isCurated, handle: handleToggleCurated, label: "Curated?" },
    { state: isFeatured, handle: handleToggleFeatured, label: "Featured?" },
    { state: isHidden, handle: handleToggleHidden, label: "Hidden?" }
  ];

  const initialUrl = `videos?no_cache=true&order_by[category]=${
    selectedOption.category
  }&order_by[order]=${
    selectedOption.order
  }&page=1&per_page=12&is_curated=${isCurated}&is_featured=${isFeatured}&is_hidden=${isHidden}&${formTagQueryURL(
    query
  )}`;
  const appendingUrl = `videos?no_cache=true&order_by[category]=${
    selectedOption.category
  }&order_by[order]=${selectedOption.order}&&page=${
    parseInt(page) + 1
  }&per_page=12&is_curated=${isCurated}&is_featured=${isFeatured}&is_hidden=${isHidden}&${formTagQueryURL(
    query
  )}`;

  useEffect(() => {
    const checkClear = async () => {
      //empty videos upon first render
      await dispatch({ type: "REPLACE", payload: [] });
    };
    checkClear();
    fetchAndDispatchContent(initialUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadTags = async () => {
      const tags = await processTags();
      setTags(tags);
    };
    loadTags();
  }, []);

  useEffect(() => {
    setPage(1);
    setHasMoreItems(true);
    fetchAndDispatchContent(initialUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedOption, isCurated, isFeatured, isHidden]);

  const fetchAndDispatchContent = async (url) => {
    try {
      const payload = await fetchResource(url);
      setIsFetching(false);
      setIsReady(true);
      dispatch({ type: "REPLACE", payload });
    } catch (err) {
      setIsFetching(false);
    }
  };

  const whatToRender = () => {
    if (isFetching) {
      return <LoadingPage />;
    }

    if (isReady) {
      if (videoState.videos.length > 0) {
        return (
          <InfiniteScroll
            pageStart={page}
            loadMore={() => appendVideo(appendingUrl, status, dispatch)}
            initialLoad={false}
            hasMore={hasMoreItems}
            loader={<Loader />}
          >
            <GridVideoDetailedContainer link={link} />
          </InfiniteScroll>
        );
      } else if (videoState.videos.length === 0 && query.length > 0) {
        return <NoResults />;
      }
    } else {
      return <ErrorPage />;
    }
  };

  return (
    <Fragment>
      {setMetadata({
        title: "Curate | Liko.gg",
        description: "Curation Page of Liko.gg.",
        url: "https://www.liko.gg/curation"
      })}
      <Paper elevation={1} square={false} className={classes.paper}>
        <SwitchForm switches={switches} />
      </Paper>
      <SearchContainer
        selectedOption={selectedOption}
        orderOptions={orderOptions}
        handleOptionChange={handleOptionChange}
        handleQueryTagChange={handleQueryTagChange}
        handleSubmitSearch={(event) =>
          handleSubmitSearch(event, status, videoState.videos, dispatch)
        }
        query={query}
        tags={tags}
      />
      {whatToRender()}
    </Fragment>
  );
};

export default CurationPage;
