import React, { useState, useContext, Fragment, useEffect } from "react";
import GridVideoPreviewContainer from "../GridDisplay/GridVideoPreviewContainer";
import SearchContainer from "../Form/SearchContainer";
import {
  fetchResource,
  formTagQueryURLByIds,
  processTags
} from "../utils/queries";
import { StateContext } from "../StateProvider";
import InfiniteScroll from "react-infinite-scroller";
import appendVideo from "../utils/appendVideo";
import Loader from "../Lotties/Loader";
import { useTheme } from "@material-ui/core/styles";
import { findOrderByLabel, orderOptions } from "../utils/orderOptions";
import NoResults from "../NoResults";
import SearchHelp from "../SearchHelp";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import setMetadata from "../utils/metadata.js";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import { isEqual } from "lodash";

const SearchPage = ({ link }) => {
  let { videoState, dispatch } = useContext(StateContext);
  const [tags, setTags] = useState([]);

  const [isFetching, setIsFetching] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  let history = useHistory();

  const status = {
    page,
    setPage,
    hasMoreItems,
    setHasMoreItems,
    loading,
    setLoading
  };

  const [query, setQuery] = useState({
    by_tag_id: [],
    "order_by[order]": findOrderByLabel("Most Recent").order,
    "order_by[category]": findOrderByLabel("Most Recent").category,
    ...queryString.parse(history.location.search, { arrayFormat: "bracket" })
  });

  const handleOptionChange = (event) => {
    const selectedOption = findOrderByLabel(event.target.value);

    setQuery({
      ...query,
      "order_by[order]": selectedOption.order,
      "order_by[category]": selectedOption.category
    });
  };

  const handleQueryTagChange = (event, tags) => {
    setQuery({
      ...query,
      by_tag_id: tags.map((t) => t.id)
    });
  };

  useEffect(() => {
    const loadTags = async () => {
      const tags = await processTags();
      setTags(tags);
    };
    loadTags();
  }, []);

  useEffect(() => {
    setPage(1);
    setHasMoreItems(true);

    const fetchAndDispatchContent = async (url) => {
      try {
        // TODO figure out why URL is wrong when coming from builds?
        const payload = await fetchResource(url);
        setIsFetching(false);
        dispatch({ type: "REPLACE", payload });
        setIsReady(true);
      } catch (err) {
        setIsFetching(false);
      }
    };

    const searchUrl = `videos?order_by[category]=${
      query["order_by[category]"]
    }&order_by[order]=${
      query["order_by[order]"]
    }&page=1&per_page=12&is_curated=true&${formTagQueryURLByIds(
      query["by_tag_id"]
    )}`;

    fetchAndDispatchContent(searchUrl);
    history.push({
      pathname: "/search",
      search:
        query["by_tag_id"].length > 0 &&
        queryString.stringify(query, { arrayFormat: "bracket" })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const q = queryString.parse(history.location.search, {
      arrayFormat: "bracket"
    });

    if (
      !isEqual(q["by_tag_id"], query["by_tag_id"]) &&
      q["by_tag_id"] &&
      q["by_tag_id"].length > 0
    ) {
      setQuery({
        by_tag_id: [],
        "order_by[order]": findOrderByLabel("Most Recent").order,
        "order_by[category]": findOrderByLabel("Most Recent").category,
        ...q
      });
    }
  }, [history.location.search, query]);

  const whatToRender = () => {
    if (isFetching) {
      return <LoadingPage />;
    }

    if (isReady) {
      if (query["by_tag_id"].length > 0 && videoState.videos.length > 0) {
        return (
          <InfiniteScroll
            pageStart={page}
            loadMore={() => {
              const appendingUrl = `videos?order_by[category]=${
                query["order_by[category]"]
              }&order_by[order]=${query["order_by[order]"]}&page=${
                parseInt(page) + 1
              }&per_page=12&is_curated=true&${formTagQueryURLByIds(
                query["by_tag_id"]
              )}`;

              appendVideo(appendingUrl, status, dispatch);
            }}
            initialLoad={false}
            hasMore={hasMoreItems}
            loader={<Loader />}
          >
            <GridVideoPreviewContainer link={link} videoState={videoState} />
          </InfiniteScroll>
        );
      } else if (
        videoState.videos.length === 0 &&
        query["by_tag_id"].length > 0
      ) {
        return <NoResults />;
      } else {
        return <SearchHelp tags={tags} />;
      }
    } else {
      return <ErrorPage />;
    }
  };

  return (
    <Fragment>
      {setMetadata({
        title: "Search | Liko.gg",
        description: "Find the ESO PVE videos you want, fast.",
        url: "https://www.liko.gg/search"
      })}
      <h1 style={{ color: theme.liko.white }}>Search</h1>
      {tags && tags.length > 0 && (
        <SearchContainer
          selectedOption={{
            order: query["order_by[order]"],
            category: query["order_by[category]"],
            label: orderOptions.find(
              (o) =>
                o.order === query["order_by[order]"] &&
                o.category === query["order_by[category]"]
            ).label
          }}
          orderOptions={orderOptions}
          handleOptionChange={handleOptionChange}
          handleQueryTagChange={handleQueryTagChange}
          queryTags={query["by_tag_id"].map((id) =>
            tags.find((t) => t.id === parseInt(id))
          )}
          tags={tags}
        />
      )}
      {whatToRender()}
    </Fragment>
  );
};

export default SearchPage;
