import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

const GridToggleInfo = ({ toggles }) => {
  const theme = useTheme();

  return (
    <Box p={0.5}>
      {toggles.map((t) => (
        <div
          key={t.label}
          style={{
            display: "flex",
            justifyContent: "center",
            color: theme.liko.white
          }}
        >
          <p>
            Is {!t.attribute && "Not"} {t.label}
          </p>
        </div>
      ))}
    </Box>
  );
};

export default GridToggleInfo;
