export const prettyViewCount = (num) => {
  if (num === undefined || num < 1000) return num;
  const ranges = [
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "k" }
  ];

  const range = ranges.find((range) => num >= range.divider);

  let precision;
  num > 99999 ? (precision = 3) : (precision = 2);

  return (num / range.divider).toPrecision(precision).toString() + range.suffix;
};

export const prettyLikeRatio = (like_count, dislike_count) => {
  if (parseInt(like_count) === 0 && parseInt(dislike_count) === 0) {
    return "No Ratings";
  }

  const like = parseInt(like_count);
  const dislike = parseInt(dislike_count);
  let precision = 2;
  if (dislike === 0) precision = 3;
  return `${((like * 100) / (like + dislike)).toPrecision(precision)}`;
};

export const prettyLikeColor = (likeRatio) => {
  if (likeRatio > 90) return 90;
  if (likeRatio > 80) return 80;
  if (likeRatio > 60) return 60;
  if (likeRatio > 40) return 40;
  if (likeRatio > 20) return 20;
  else return 10;
};
