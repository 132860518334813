import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TimeAgo from "react-timeago";
import {
  prettyLikeColor,
  prettyViewCount,
  prettyLikeRatio
} from "../utils/prettyStats";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "auto",
    maxWidth: "800px",
    alignItems: "center"
  },
  text: {
    textAlign: "center",
    color: theme.liko.white,
    paddingRight: "0.5em"
  }
}));

const GridVideoSecondary = ({ channel, published_at, videoStats }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { like_count, dislike_count, view_count } = videoStats;
  const likeRatio = prettyLikeRatio(like_count, dislike_count);

  const stats = [
    {
      label: "likeRatio",
      count: `${likeRatio}${likeRatio > 0 ? "%" : ""}`,
      color: theme.liko.rating.dark[prettyLikeColor(likeRatio)]
    },
    {
      label: "viewCount",
      count: prettyViewCount(view_count)
    }
  ];

  return (
    <Box p={0.5}>
      <Typography variant="body2" color="textSecondary" component="h4">
        <a
          style={{
            flexGrow: 1,
            textAlign: "left",
            textDecoration: "none",
            color: theme.liko.white
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.youtube.com/channel/${channel.youtube_channel_id}`}
        >
          <strong>{channel.title}</strong>
        </a>
      </Typography>
      <Box className={classes.container}>
        <Typography variant="body2" noWrap={false} className={classes.text}>
          {stats[1].count} views
        </Typography>
        <Typography variant="body2" noWrap={false} className={classes.text}>
          &#8226;
        </Typography>
        <Typography variant="body2" noWrap={false} className={classes.text}>
          <TimeAgo date={published_at} />
        </Typography>
        <Typography variant="body2" noWrap={false} className={classes.text}>
          &#8226;
        </Typography>
        <Typography
          variant="body2"
          noWrap={false}
          className={classes.text}
          style={{ color: stats[0].color }}
        >
          {stats[0].count}
        </Typography>
      </Box>
    </Box>
  );
};

export default GridVideoSecondary;
