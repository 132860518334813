import React from "react";
import SearchBar from "./SearchBar";
import SearchOrderSelect from "./SearchOrderSelect";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1em",
    marginBottom: "3em",
    background: theme.liko.secondary.light
  },
  formItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em"
  }
}));

const SearchContainer = ({
  selectedOption,
  orderOptions,
  handleOptionChange,
  handleQueryTagChange,
  queryTags,
  tags
}) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.paper}>
      <div className={classes.formItem}>
        <SearchBar
          handleQueryTagChange={handleQueryTagChange}
          queryTags={queryTags}
          tags={tags}
        />
      </div>
      {orderOptions.length > 0 && (
        <div className={classes.formItem}>
          <SearchOrderSelect
            options={orderOptions}
            selectedOption={selectedOption}
            handleChange={handleOptionChange}
          />
        </div>
      )}
    </Paper>
  );
};

export default SearchContainer;
