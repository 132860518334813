import React from "react";
import GridVideoPreviewCard from "./GridVideoPreviewCard";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import "./css/slick.css";
import "./css/slick-theme.css";
import NoResultsFilter from "../NoResultsFilter";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "40px",
    [theme.breakpoints.up("s")]: {
      padding: "10px"
    }
  },
  cardContainer: {
    padding: "1em",
    height: "100%"
  }
}));

const GridVideoCarousel = ({ link, videos = [] }) => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    swipeToSlide: true,
    className: "slickslides",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  };

  const mapVideos = () => {
    if (videos.length <= 0) {
      return <NoResultsFilter />;
    }
    return videos.map((video) => {
      return (
        <div key={video.id} className={classes.cardContainer}>
          <GridVideoPreviewCard link={link} {...video} />
        </div>
      );
    });
  };

  return (
    <div className={classes.container}>
      <Slider {...settings}>{mapVideos()}</Slider>
    </div>
  );
};

export default GridVideoCarousel;
