import React from "react";
import SearchSad from "./Lotties/SearchSad";
import { useTheme } from "@material-ui/core/styles";

const NoResults = () => {
  const theme = useTheme();
  return (
    <div style={{ color: theme.liko.white, textAlign: "center" }}>
      <h2>We couldn't find any results for that combination of tags!</h2>
      <SearchSad />
      <h3>Please try again!</h3>
    </div>
  );
};

export default NoResults;
