import React from "react";
import { default as MButton } from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";

const Button = ({ label }) => {
  const theme = useTheme();

  return (
    <MButton style={{ color: theme.liko.white }} variant="outlined">
      {label}
    </MButton>
  );
};

export default Button;
