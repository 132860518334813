import React, { useState, useEffect } from "react";
import VideoThumbnail from "../Video/VideoThumbnail";
import GridChannelInfo from "./GridChannelInfo";
import GridVideoInfo from "./GridVideoInfo";
import GridToggleInfo from "./GridToggleInfo";
import GridVideoStats from "./GridVideoStats";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import GridTagGroup from "./GridTagGroup";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import slugify from "../utils/slugify";

const GridVideoPreviewCard = ({
  id,
  channel,
  tags,
  title,
  duration,
  youtube_video_id,
  thumbnail_medium,
  is_curated,
  is_featured,
  is_hidden,
  published_at,
  comment_count,
  like_count,
  dislike_count,
  view_count,
  link
}) => {
  const theme = useTheme();

  const [isLoaded, setIsLoaded] = useState(false);
  const [raised, setRaised] = useState(false);

  const videoStats = { comment_count, like_count, dislike_count, view_count };

  const toggleRaised = () => {
    setRaised(!raised);
  };

  useEffect(() => {
    if (channel) setIsLoaded(true);
  }, [channel]);

  const toggles = [
    { attribute: is_curated, label: "Curated" },
    { attribute: is_featured, label: "Featured" },
    { attribute: is_hidden, label: "Hidden" }
  ];

  return (
    <Card
      onMouseOver={toggleRaised}
      onMouseOut={toggleRaised}
      raised={raised}
      style={{ height: "100%", background: theme.liko.secondary.main }}
    >
      {link !== "/video" && (
        <CardActionArea>
          <Link
            to={{
              pathname: `${link}/${id}/${slugify(channel.title)}-${slugify(
                title
              )}`,
              state: { youtube_video_id: youtube_video_id }
            }}
          >
            <VideoThumbnail thumbnail={thumbnail_medium} duration={duration} />
          </Link>
        </CardActionArea>
      )}
      {isLoaded && (
        <CardContent>
          <GridVideoInfo
            id={id}
            link={link}
            youtube_video_id={youtube_video_id}
            title={title}
            channel={channel}
          />
          <GridChannelInfo
            link={link}
            channel={channel}
            published_at={published_at}
          />
          <GridVideoStats videoStats={videoStats} />
          <GridTagGroup tags={tags} />
          {link === "/curation" && <GridToggleInfo toggles={toggles} />}
        </CardContent>
      )}
    </Card>
  );
};

export default GridVideoPreviewCard;
