import React from "react";
import EsoSet from "../EsoComponents/EsoSet";
import EsoSkillCard from "../EsoComponents/EsoSkillCard";

export const getSet = (id, sets) => {
  return sets.find(set => set.id === id);
};

export const mapSets = (setIds, sets) => {
  return setIds.map(id => <EsoSet key={id} set={getSet(id, sets)} />);
};

export const getSkill = (id, skills) => {
  return skills.find(skill => skill.id === id);
};

export const mapSkillBar = (skillIds, skills) => {
  return skillIds.map(id => getSkill(id, skills));
};

export const mapSkillCard = (chosenSkills, skills) => {
  return chosenSkills.map(skill => (
    <EsoSkillCard
      skill={getSkill(skill.id, skills)}
      description={skill.description}
    />
  ));
};
