import React, { Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TimeAgo from "react-timeago";

var decode = require("unescape");

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    maxWidth: "800px",
    alignItems: "center",
    padding: "1em"
  }
});

const GridChannelInfo = ({ link, channel, published_at }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box p={0.5}>
      <Typography variant="body1" color="textSecondary" component="h4">
        <div className={classes.container}>
          <Fragment>
            <a
              style={{ flexGrow: 1, textAlign: "left" }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.youtube.com/channel/${channel.youtube_channel_id}`}
            >
              <Avatar
                alt={decode(channel.title)}
                src={channel.thumbnail_default}
              />
            </a>
            <a
              style={{
                flexGrow: 1,
                textAlign: "left",
                textDecoration: "none",
                color: theme.liko.white
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.youtube.com/channel/${channel.youtube_channel_id}`}
            >
              <strong>{channel.title}</strong>
            </a>
          </Fragment>

          <TimeAgo
            style={{
              flexGrow: 20,
              textAlign: "right",
              color: theme.liko.white
            }}
            date={published_at}
          />
        </div>
      </Typography>
    </Box>
  );
};

export default GridChannelInfo;
