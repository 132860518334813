const sorcerer = {
  daedric_prey: 144,
  crystal_fragments: 31,
  bound_aegis: 154,
  twilight_tormentor: 147,
  empowered_ward: 151,
  greater_storm_atronach: 138,
  boundless_storm: 167,
  lightning_flood: 170,
  critical_surge: 173,
  scamp: 142
};

const destructiveStaff = {
  force_pulse: 363,
  unstable_wall: 365,
  elemental_blockade: 366,
  destructive_reach: 369
};

const soulMagic = {
  consuming_trap: 507
};

const magesGuild = {
  scalding_rune: 593,
  spell_symmetry: 595,
  inner_light: 586,
  shooting_star: 584,
  mystic_orb: 638
};

const fightersGuild = {
  barbed_trap: 575
};

const psijicGuild = {
  elemental_weapon: 609,
  channeled_acceleration: 612
};

const sL = {
  sorcerer,
  destructiveStaff,
  soulMagic,
  magesGuild,
  fightersGuild,
  psijicGuild
};

export default sL;
