import React, { useEffect, useState, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AboutPage from "./Pages/AboutPage";
import ArenasPage from "./Pages/ArenasPage/ArenasPage";
import BuildsPage from "./Pages/BuildsPage/BuildsPage";
import HomePage from "./Pages/HomePage";
import CurationPage from "./Pages/CurationPage";
import CurationShowPage from "./Pages/CurationShowPage";
import HelpPage from "./Pages/HelpPage";
import ErrorPage from "./Pages/ErrorPage";
import SearchPage from "./Pages/SearchPage";
import TrialsPage from "./Pages/TrialsPage/TrialsPage";
import VideoListPage from "./Pages/VideoListPage";
import videoListPageTemplates from "./templates/videoListPage";
import NavDrawer from "./Universal/NavDrawer";
import ContentPage from "./Pages/ContentPage";
import Container from "@material-ui/core/Container";
import {
  drawerWidth,
  calculatePaddingLeft,
  calculatePaddingTop
} from "./utils/styling";
import { useTheme } from "@material-ui/core/styles";

import history from "./history";

const App = () => {
  history.listen((location, action) => {
    window.scrollTo(0, 0);
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const theme = useTheme();

  return (
    <Fragment>
      <NavDrawer drawerWidth={drawerWidth} />
      <div
        style={{
          paddingLeft: calculatePaddingLeft(),
          paddingTop: calculatePaddingTop(),
          backgroundColor: theme.liko.secondary.dark,
          minHeight: "100vh"
        }}
      >
        <Container maxWidth="xl">
          <Switch>
            <Route exact path="/">
              <HomePage link={"/content"} />
            </Route>
            <Route path="/content/:id/:slug?">
              <ContentPage link={"/video"} />
            </Route>
            <Route path="/liko">
              <VideoListPage {...videoListPageTemplates.liko} />
            </Route>
            <Route path="/community">
              <VideoListPage {...videoListPageTemplates.community} />
            </Route>
            <Route path="/builds">
              <BuildsPage link={"/content"} />
            </Route>
            <Route path="/trials">
              <TrialsPage link={"/content"} />
            </Route>
            <Route path="/arenas">
              <ArenasPage link={"/content"} />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/help">
              <HelpPage />
            </Route>
            <Route path="/search">
              <SearchPage link={"/content"} />
            </Route>
            <Route path="/secret">
              <HomePage link={"/content"} />
            </Route>
            <PrivateRoute path="/curation/:id/:slug?">
              <CurationShowPage link={"/curationShow"} />
            </PrivateRoute>
            <PrivateRoute path="/curation">
              <CurationPage link={"/curation"} />
            </PrivateRoute>
            <Redirect to="/" />
            <Route path="*">
              <ErrorPage />
            </Route>
          </Switch>
        </Container>
      </div>
    </Fragment>
  );
};

export default App;
