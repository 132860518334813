const URL_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://liko-gg.herokuapp.com/"
    : "http://localhost:3000/";

export const fetchResource = async (thing) => {
  const res = await fetch(`${URL_ENDPOINT}${thing}`);
  const data = res.json();
  return data;
};

export const patchResource = async (thing, body) => {
  const res = await fetch(`${URL_ENDPOINT}${thing}`, {
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body
  });

  return await res.json();
};

export const formTagQueryURL = (tags) => {
  return tags
    .map((q, index) => {
      let string = `by_tag_id[]=${q.id}`;
      return index === tags.length - 1 ? string : `${string}&`;
    })
    .join("");
};

export const formTagQueryURLByIds = (tagIds) => {
  return tagIds
    .map((id, index) => {
      let string = `by_tag_id[]=${id}`;
      return index === tagIds.length - 1 ? string : `${string}&`;
    })
    .join("");
};

export const findVideo = (videos, id) => {
  return videos.find((v) => v.id === parseInt(id));
};

export const getTagsFromHistory = (searchString, tags) => {
  const ids = searchString.split("by_tag_id[]=").slice(1);
  return generateTagsFromIds(ids, tags);
};

export const processTags = async () => {
  let tags = await fetchResource("tags");

  const anyTags = [];
  const otherTags = [];
  for (const t of tags) {
    if (t.label === t.full_path) continue;
    t.label.includes("Any") ? anyTags.push(t) : otherTags.push(t);
  }

  return [...anyTags, ...otherTags];
};

export const getTagsOnLoad = (params, tags) => {
  const ids = params.by_tag_id;
  return generateTagsFromIds(ids, tags);
};

const generateTagsFromIds = (ids, tags) => {
  return ids.map((id) => {
    return tags.find((tag) => tag.id === parseInt(id));
  });
};
