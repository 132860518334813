import React from "react";
import CardMedia from "@material-ui/core/CardMedia";

var ytDurationFormat = require("youtube-duration-format");

const VideoThumbnail = ({ thumbnail, duration }) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", right: "5px", bottom: "5px" }}>
        <p
          style={{
            fontSize: "15px",
            margin: "0px",
            padding: "2px",
            backgroundColor: "black",
            color: "white"
          }}
        >
          {duration !== undefined && ytDurationFormat(duration)}
        </p>
      </div>
      <CardMedia image={thumbnail} component="img" alt="video thumbnail"></CardMedia>
    </div>
  );
};

export default VideoThumbnail;
