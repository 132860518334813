import React from "react";
import { useAuth } from "react-use-auth";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, path }) => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Route path={path}>
      {isAuthenticated &&
      (user.email === "likoxie@gmail.com" ||
        user.email === "stileanima@gmail.com") ? (
        children
      ) : (
        <Redirect to="/" />
      )}
    </Route>
  );
};

export default PrivateRoute;
