import React from "react";

const EsoSkill = ({ skill }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://eso-skillbook.com/skill/${skill.slug}`}
    >
      <img
        width="100%"
        src={`https://beast.pathfindermediagroup.com/storage/icons/${skill.icon}`}
        alt={`${skill.slug}`}
      />
    </a>
  );
};

export default EsoSkill;
