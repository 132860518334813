import React, { useState, useEffect, useContext, Fragment } from "react";
import GridVideoCarousel from "../GridDisplay/GridVideoCarousel";
import { fetchResource } from "../utils/queries";
import { StateContext } from "../StateProvider";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import setMetadata from "../utils/metadata.js";
import { Link } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import Button from "../Universal/Button";
import ErrorPage from "./ErrorPage";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1em",
    marginBottom: "3em",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    color: theme.liko.white,
    background: theme.liko.primary.main
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.liko.white
  },
  title: {
    color: theme.liko.white
  },
  link: {
    color: theme.liko.white,
    textDecoration: "none"
  }
}));

const sections = [
  {
    params: {
      page: 1,
      per_page: 8,
      is_curated: true,
      "by_tag_id[]": [1040, 1041] //Blackwood and Blackwood pts
    },
    link: "/search?by_tag_id[]=1040&by_tag_id[]=1041",
    label: "Latest on the new Blackwood Chapter"
  },
  {
    params: {
      is_featured: true
    },
    label: "Featured Videos from The Community",
    link: "/community"
  },
  {
    params: {
      page: 1,
      per_page: 8,
      is_curated: true,
      is_featured: false,
      "by_tag_id[]": [720] //builds
    },
    link: "/builds",
    label: "Latest PVE Builds"
  },
  {
    params: {
      page: 1,
      per_page: 8,
      is_curated: true,
      is_featured: false,
      "by_tag_id[]": [733] //trials
    },
    link: "/trials",
    label: "Latest 12-Person Trials Gameplay"
  }
];

const HomePage = ({ link }) => {
  const classes = useStyles();

  let { videoState, dispatch } = useContext(StateContext);

  const [isFetching, setIsFetching] = useState(true);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = async () => {
    try {
      const payload = await Promise.all(
        sections.map(async (section) => {
          const videos = await fetchResource(
            `videos?${queryString.stringify(section.params)}`
          );
          return {
            ...section,
            videos
          };
        })
      );
      dispatch({
        type: "REPLACE",
        payload
      });
      setIsFetching(false);
      setIsReady(true);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const whatToRender = () => {
    if (isFetching) {
      return <LoadingPage />;
    }

    if (isReady) {
      return mapVideoSections();
    } else {
      return <ErrorPage />;
    }
  };

  const mapVideoSections = () => {
    const sections = videoState.videos;
    return sections.map(
      (section, index) =>
        section.videos.length > 0 && (
          <Fragment key={index}>
            <div className={classes.container}>
              <h2>{section.label}</h2>
              <Link className={classes.link} to={section.link}>
                <Button label="See More" />
              </Link>
            </div>
            <GridVideoCarousel link={link} videos={section.videos} />
          </Fragment>
        )
    );
  };

  return (
    <>
      {setMetadata({
        title: "Home | Liko.gg",
        description:
          "Welcome to the homepage of Liko.gg - a PVE content discovery platform for The Elder Scrolls Online, by Liko.",
        url: "https://www.liko.gg/help"
      })}
      <Paper elevation={4} className={classes.paper}>
        <div>
          <h1 style={{ textAlign: "center" }}>Welcome to Liko.gg</h1>
          <h2 style={{ textAlign: "center" }}>
            The best place to find Community-made PVE content for The Elder
            Scrolls Online.
          </h2>
        </div>
      </Paper>

      {whatToRender()}
    </>
  );
};

export default HomePage;
