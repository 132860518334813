const magicka = {
  p_siroria: 339,
  zaan: 328,
  sorrow: 186,
  vma_staff: 74,
  p_false_god: 396,
  spell_strat: 352,
  nma: 410,
  bsw: 225,
  necropotence: 126
};

const sG = {
  magicka
};

export default sG;
