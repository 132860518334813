import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "react-use-auth";
import StateProvider from "./StateProvider";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./Universal/ScrollToTop";
import Theme from "./Theme";
import GA from "./utils/GoogleAnalytics";
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <AuthProvider
    auth0_domain="dev-x3zyo85a.eu.auth0.com"
    auth0_client_id="iEzGcvHl4mp3onpM6BYeeJDOX8qnU92o"
  >
    <SnackbarProvider>
      <StateProvider>
        <Theme>
          <Router>
            {GA.init() && <GA.RouteTracker />}
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </Router>
        </Theme>
      </StateProvider>
    </SnackbarProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
