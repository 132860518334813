import React, { useContext } from "react";
import GridVideoPreviewCard from "./GridVideoPreviewCard";
import Grid from "@material-ui/core/Grid";
import { StateContext } from "../StateProvider";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center"
  }
});

const GridVideoPreviewContainer = ({ link, videos = [] }) => {
  let { videoState } = useContext(StateContext);

  if (!videos.length) {
    videos = videoState.videos;
  }

  const mapVideos = () => {
    return videos.map((video) => {
      return (
        <Grid key={video.id} item lg={3} md={4} sm={12} xs={12}>
          <GridVideoPreviewCard link={link} {...video} />
        </Grid>
      );
    });
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.container} spacing={3}>
      {mapVideos()}
    </Grid>
  );
};

export default GridVideoPreviewContainer;
