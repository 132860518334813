import React from "react";
import DHMagSorc1Pet from "./dragonhold/DHMagSorc1Pet";

const postRouter = {
  u9KhRfh4pag: {
    type: "magicka",
    component: <DHMagSorc1Pet />
  }
};

export default postRouter;
