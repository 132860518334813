import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import GridVideoDescription from "../GridDisplay/GridVideoDescription";
import { useTheme } from "@material-ui/core/styles";

const GridVideoShowCard = ({ id, description }) => {
  const [raised, setRaised] = useState(false);
  const theme = useTheme();
  const toggleRaised = () => {
    setRaised(!raised);
  };
  return (
    <Card
      onMouseOver={toggleRaised}
      onMouseOut={toggleRaised}
      raised={raised}
      style={{ height: "100%", background: theme.liko.secondary.main }}
    >
      <GridVideoDescription description={description} />
    </Card>
  );
};

export default GridVideoShowCard;
