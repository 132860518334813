import React, { useContext, Fragment } from "react";
import RaisedCard from "../../Universal/RaisedCard";
import Grid from "@material-ui/core/Grid";
import sL from "../../esoReferences/skills";
import sG from "../../esoReferences/sets";
import { makeStyles } from "@material-ui/core/styles";
import EsoSkillBar from "../../EsoComponents/EsoSkillBar";
import { StateContext } from "../../StateProvider";
import { mapSkillBar, mapSkillCard, mapSets } from "../../utils/esoHelper";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center"
  },
  title: {
    color: theme.liko.white
  }
}));

const alternateSkills = [
  {
    id: sL.magesGuild.mystic_orb,
    description:
      "A MUST-HAVE skill if you want to include more AOE damage into your setup. Upsides also include a nice group synergy, but the downside is the higher resource cost."
  },
  {
    id: sL.destructiveStaff.force_pulse,
    description:
      "A great spammable alternative if there is opportunity for cleave damage. A must-have if the Asylum Perfected Destruction Staff is in use."
  },
  {
    id: sL.destructiveStaff.destructive_reach,
    description:
      "If you wanted to add another single-target DOT. Provides some extra sources of Burning damage and cost is relatively cheap, but damage isn't as good as some other options."
  },
  {
    id: sL.magesGuild.scalding_rune,
    description:
      "Another option for an alternative DOT. More damage per cast than Flame Reach but also costlier."
  },
  {
    id: sL.sorcerer.lightning_flood,
    description:
      "An AOE ability which provides a unique synergy for the group. Costs more than it did in the previous patch. Liquid Lightning would work too."
  },
  {
    id: sL.sorcerer.bound_aegis,
    description:
      "If you want a simpler rotation, you could drop a DOT. Inner Light - same as above, but Bound Aegis is better."
  },
  {
    id: sL.sorcerer.empowered_ward,
    description:
      "You could replace a DOT or Spell Symmetry if you can make a setup to sustain without if you wanted to include a shield. Any other shield will work too."
  },
  {
    id: sL.destructiveStaff.elemental_blockade,
    description:
      "Using this morph instead of Unstable Wall can also be a decent option. This option offers more cleave, and a longer duration, therefore better sustain, since this skill is costly."
  },
  {
    id: sL.soulMagic.consuming_trap,
    description:
      "A DOT that can help sustain if used in trash fights or on smaller mobs in Boss Fights, due to the resource return."
  },
  {
    id: sL.psijicGuild.channeled_acceleration,
    description:
      "If you prefer this over Barbed Trap, this is fine. Magicka sustain will suffer a little but might be easier to use in some cases."
  },
  {
    id: sL.sorcerer.critical_surge,
    description:
      "An ability which is primarily used for the healing. The other morph, Power Surge also provides Major Sorcery for yourself."
  },
  {
    id: sL.sorcerer.scamp,
    description:
      "Check out the other Pet Sorc videos, linked above for 2-Pet Sorc. Or you can replace the Twilight with the Scamp directly. Offers good AOE damage."
  }
];

const mainGear = [
  sG.magicka.p_false_god,
  sG.magicka.spell_strat,
  sG.magicka.nma,
  sG.magicka.bsw,
  sG.magicka.necropotence
];

const alternativeGear = [
  sG.magicka.p_false_god,
  sG.magicka.spell_strat,
  sG.magicka.nma,
  sG.magicka.bsw,
  sG.magicka.necropotence
];

const skillBar = [
  sL.sorcerer.daedric_prey,
  sL.psijicGuild.elemental_weapon,
  sL.sorcerer.crystal_fragments,
  sL.sorcerer.bound_aegis,
  sL.sorcerer.twilight_tormentor,
  sL.magesGuild.shooting_star,
  sL.destructiveStaff.unstable_wall,
  sL.fightersGuild.barbed_trap,
  sL.magesGuild.spell_symmetry,
  sL.sorcerer.boundless_storm,
  sL.sorcerer.twilight_tormentor,
  sL.sorcerer.greater_storm_atronach
];

const DHMagSorc1Pet = () => {
  const classes = useStyles();
  let { videoState } = useContext(StateContext);
  let { sets, skills } = videoState;
  return (
    <Fragment>
      <h2 className={classes.title}>
        This is a Magicka Sorcerer 1-Pet PVE build and Iron Atronach Parse for
        the Dragonhold patch.
      </h2>
      <RaisedCard>
        <p>
          The 1-Pet Magicka Sorcerer (generally) brings the best balance of
          damage and practicality out of the different setups.
        </p>
        <p>
          For this reason, it has been the most popular setup, and maybe it will
          continue to be this way. Having said, the damage difference between
          various Magicka Sorcerer setups look to be smaller than we have seen
          in while too.
        </p>
        <p>
          Magicka Sorcerer has received a set of changes which brings it away
          from the style of rotation from Scalebreaker, and a little bit closer
          to that from Elsweyr and the few patches previously.
        </p>
      </RaisedCard>

      <RaisedCard>
        <p>
          Thanks to @Aerenel, who raids with Wipes on Trash (WoT) PC/NA, for
          providing these parses. His Discord ID is @Aerenel#8604 should you
          want to ask him about Magicka Sorcerer.
        </p>
        <p>
          Here are the other MagSorc parses from Dragonhold, again kindly
          provided by @Aerenel.
        </p>
        <p>2-Pet Magicka Sorcerer video here -- https://youtu.be/8ckdDI6V1uk</p>
        <p>
          No-Pet Magicka Sorcerer video here -- https://youtu.be/moyPcWL4zlA
        </p>
      </RaisedCard>

      <h2 className={classes.title}>Test vs Raid Setups</h2>
      <RaisedCard>
        <p>
          I am using the Max Magicka/Magicka Regen food, with 64 points into
          Magicka for all Magicka Setups in these rotations.
        </p>
        <p>
          Gear used here: 5x Mother's Sorrow Body, 5x Siroria front-barred, 2x
          Zaan. (See build screenshot for full details). The above is used
          simply for purposes of comparison against other classes, in this test
          environment.
        </p>
        <p>
          Depending on the situation, you may consider different gear, skills
          and food. There is no one setup which fits all.
        </p>
      </RaisedCard>

      <Grid container className={classes.container} spacing={3}>
        {mapSets(mainGear, sets)}
      </Grid>

      <br />
      <RaisedCard>
        <h2>Race</h2>
        <p>
          Altmer. <br />
          Other good alternatives include: Breton, Dunmer, Khajiit
        </p>
        <h2>Food</h2>
        <p>
          In real raid situations, consider Clockwork Citrus Fillet or Artaeum
          Pickled Fish Bowl as food alternatives.
        </p>
      </RaisedCard>

      <h2 className={classes.title}>
        Other Gear Sets (Situationally dependant)
      </h2>
      <Grid container className={classes.container} spacing={3}>
        {mapSets(alternativeGear, sets)}
      </Grid>

      <h2 className={classes.title}>
        CMX Parse, Build (Gear, Skills, CPs), Superstar from Video
      </h2>
      <Grid container className={classes.container} spacing={3}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <img
            src="https://i.imgur.com/eqavZPl.png"
            width="100%"
            alt="cmx parse"
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <img
            src="https://i.imgur.com/BwvdwpP.png"
            width="100%"
            alt="cmx build"
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <img
            src="https://i.imgur.com/r20bADl.png"
            width="100%"
            alt="superstar"
          />
        </Grid>
      </Grid>

      <h2 className={classes.title}>Example Skill Bar</h2>
      <RaisedCard>
        <EsoSkillBar skills={mapSkillBar(skillBar, skills)} />
      </RaisedCard>

      <h2 className={classes.title}>Rotation</h2>
      <RaisedCard>
        <p>
          Rotation Focus on keeping a great up-time with Unstable Wall. Cast
          Daedric Prey on cooldown, so every 6 seconds, but never before. This
          skill is useful to keep up because it has a relatively low Magicka
          cost, and also gives a damage boost to your Twilight and Atronach
          Ultimate. Beyond that, Keep up Barbed Trap and Boundless Storm, which
          is also great for its utility. This means you'll be more optimal when
          playing as close to melee whenever possible.
        </p>
        <p>
          You can try some of the alternative skills below if you are more
          comfortable at range. Use Elemental Weapon as your spammable and
          Crystal Fragments as frequently as possible. You can easily switch out
          the Twilight for the Scamp, if you wish to have a more imvolved
          rotation, or for fights where the extra cleave from the Scamp may be
          better!
        </p>
      </RaisedCard>

      <h2 className={classes.title}>Proposed Static Rotation</h2>
      <RaisedCard>
        <p>12-second Rotation as the base structure.</p>
        <p>Start: Boundless - Barbed Trap </p>
        <p>
          Wall - Prey - Boundless/Spam - Spam - Spam - Spam/Barbed Trap Spam -
          Prey - Spam - Spam - Spam - Spam/Barbed Trap
        </p>
        <p>
          Spam = Elemental Weapon / Crystal Frags / Ultimate / Spell Symmetry -
          use whichever is most suitable at any given moment
        </p>
        <p>
          Every other rotation, alternate Boundless Storm with Spam. Cast Barbed
          Trap every 1.5 rotations, at spots where Spam/Barbed Trap is written.
        </p>
      </RaisedCard>

      <h2 className={classes.title}>
        Alternative skills for Magicka Sorcerer:
      </h2>
      <Grid
        container
        className={classes.container}
        style={{ paddingBottom: "2em" }}
        spacing={2}
      >
        {mapSkillCard(alternateSkills, skills)}
      </Grid>
    </Fragment>
  );
};

export default DHMagSorc1Pet;
