import React from "react";
import Heart from "../Lotties/Heart";

const LoadingPage = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}
  >
    <Heart />
  </div>
);

export default LoadingPage;
