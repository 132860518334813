import { fetchResource } from "./queries";

const appendVideo = async (url, status, dispatch) => {
  if (status.loading) return;
  status.setLoading(true);
  const payloadLength = await videoDispatch(url, "APPEND", dispatch);
  payloadLength < 12 && status.setHasMoreItems(false);
  status.setPage(status.page + 1);
  status.setLoading(false);
};

const videoDispatch = async (url, type, dispatch) => {
  const payload = await fetchResource(url);
  dispatch({ type, payload });
  return payload.length;
};

export default appendVideo;
