import React from "react";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    color: theme.liko.white
  }
}));

const Tag = ({ tag }) => {
  const classes = useStyles();
  return (
    <Link
      key={tag.id}
      style={{ textDecoration: "none" }}
      to={`/search?by_tag_id[]=${tag.id}`}
    >
      <Box p={0.3}>
        <Chip
          className={classes.chip}
          clickable
          label={tag.short_path}
          variant="outlined"
        />
      </Box>
    </Link>
  );
};

export default Tag;
