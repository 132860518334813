const template = {
    liko: {
      title: "Liko",
      description:
        "This is a collection of Liko's latest content for The Elder Scrolls Online",
      link: "/content",
      params: {
        per_page: 12,
        is_curated: true,
        by_creator: "UCuLGCNYH1t5DyQQ5tfU4Hdw",
        page: 1
      },
      metadata: {
        title: "Liko | Liko.gg",
        description: "Liko's Elder Scrolls Online content, curated by Liko.gg.",
        url: "https://www.liko.gg/help"
      }
    },
    community: {
      title: "Discover",
      description:
        "This is a collection of the latest community-made PVE content for The Elder Scrolls Online",
      link: "/content",
      params: {
        per_page: 12,
        is_curated: true,
        page: 1
      },
      metadata: {
        title: "Discover | Liko.gg",
        description: "Discover the latest content from the ESO Community.",
        url: "https://www.liko.gg/community"
      }
    }
  };

  export default template;