import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import VideoPlayer from "../Video/VideoPlayer";
import CurationContainer from "../Form/CurationContainer";
import SwitchForm from "../Form/SwitchForm";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { fetchResource, patchResource, processTags } from "../utils/queries";
import { StateContext } from "../StateProvider";
import GridVideoDetailedCard from "../GridDisplay/GridVideoDetailedCard";
import GridVideoDescription from "../GridDisplay/GridVideoDescription";
import setMetadata from "../utils/metadata.js";
import slugify from "../utils/slugify";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "2.5em"
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1em",
    padding: "0.5em",
    background: theme.liko.secondary.light
  }
}));

const CurationShowPage = ({ link }) => {
  const classes = useStyles();
  let { id, slug } = useParams();
  const history = useHistory();
  let { videoState, dispatch } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const video = videoState.videos[0];

  const [isFetching, setIsFetching] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const [queryTags, setQueryTags] = useState([]);
  const [isCurated, setIsCurated] = useState(true);
  const [isFeatured, setIsFeatured] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetchAndDispatchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slug]);

  useEffect(() => {
    const loadTags = async () => {
      const tags = await processTags();
      setTags(tags);
    };
    loadTags();
    fetchAndDispatchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAndDispatchContent = async () => {
    try {
      const payload = await fetchResource(`videos/${id}`);
      await dispatch({ type: "REPLACE", payload });
      setIsFetching(false);
      setIsReady(true);
      history.push(
        `/curation/${payload[0].id}/${slugify(
          payload[0].channel.title
        )}-${slugify(payload[0].title)}`
      );
      setQueryTags(payload[0].tags);
      setIsFeatured(payload[0].is_featured);
      setIsCurated(payload[0].is_curated);
      setIsHidden(payload[0].is_hidden);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const handleToggleCurated = () => {
    setIsCurated(!isCurated);
  };

  const handleToggleFeatured = () => {
    setIsFeatured(!isFeatured);
  };

  const handleToggleHidden = () => {
    setIsHidden(!isHidden);
  };

  const switches = [
    { state: isCurated, handle: handleToggleCurated, label: "Curated?" },
    { state: isFeatured, handle: handleToggleFeatured, label: "Featured?" },
    { state: isHidden, handle: handleToggleHidden, label: "Hidden?" }
  ];

  const handleSubmitSearch = async (event) => {
    event.preventDefault();
    try {
      const body = JSON.stringify({
        tags: queryTags,
        is_curated: isCurated,
        is_featured: isFeatured,
        is_hidden: isHidden
      });

      const data = await patchResource(`videos/${id}`, body);
      dispatch({ type: "REPLACE", payload: [data] });
      enqueueSnackbar("Updated Successfully!", {
        variant: "success",
        autoHideDuration: 3000
      });
    } catch (e) {
      enqueueSnackbar("Failed to Update :(", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

  const handleQueryTagChange = (event, value) => {
    setQueryTags(value);
  };

  const setStateToCurrent = () => {
    setQueryTags(video.tags);
    setIsFeatured(video.is_featured);
    setIsCurated(video.is_curated);
    setIsHidden(video.is_hidden);
  };

  const whatToRender = () => {
    if (isFetching) {
      return <LoadingPage />;
    }

    if (isReady) {
      return (
        <Fragment>
          <div className={classes.paper}>
            <VideoPlayer videoId={video.youtube_video_id} />
          </div>
          <div className={classes.paper}>
            <GridVideoDetailedCard link={link} {...video} />
          </div>

          <Paper elevation={5} square={false} className={classes.paper}>
            <Button onClick={setStateToCurrent} variant="outlined">
              Reset Changes
            </Button>
          </Paper>
          <Paper elevation={5} square={false} className={classes.paper}>
            <SwitchForm switches={switches} />
          </Paper>
          <Paper elevation={5} square={false}>
            <CurationContainer
              handleQueryTagChange={handleQueryTagChange}
              handleSubmitSearch={handleSubmitSearch}
              currentTags={video.tags}
              queryTags={queryTags}
              tags={tags}
            />
          </Paper>
          <GridVideoDescription description={video.description} />
        </Fragment>
      );
    } else {
      return <ErrorPage />;
    }
  };

  return (
    <div className={classes.container}>
      {setMetadata({
        title: `Curate ${id} | Liko.gg`,
        description: `Curate Content ${id} on Liko.gg`,
        url: `https://www.liko.gg/curation/${id}`
      })}
      {whatToRender()}
    </div>
  );
};

export default CurationShowPage;
