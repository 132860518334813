import React, { useState, useEffect } from "react";
import VideoThumbnail from "../Video/VideoThumbnail";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import slugify from "../utils/slugify";
import GridVideoPrimary from "./GridVideoPrimary";
import GridVideoSecondary from "./GridVideoSecondary";
import GridTagGroup from "./GridTagGroup";

const useStyles = makeStyles({
  cardContent: {
    padding: "0.5em",
    "&:last-child": {
      paddingBottom: "0.5em"
    }
  }
});

const GridVideoPreviewCard = ({
  id,
  channel,
  tags,
  title,
  duration,
  is_featured,
  youtube_video_id,
  thumbnail_medium,
  published_at,
  comment_count,
  like_count,
  dislike_count,
  view_count,
  link
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [raised, setRaised] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const videoStats = { comment_count, like_count, dislike_count, view_count };

  const background = is_featured ? theme.liko.featured : theme.liko.secondary.main;

  const toggleRaised = () => {
    setRaised(!raised);
  };

  useEffect(() => {
    if (channel) setIsLoaded(true);
  }, [channel]);

  return (
    <Card
      onMouseOver={toggleRaised}
      onMouseOut={toggleRaised}
      raised={raised}
      style={{ height: "100%", background }}
    >
      {link !== "/video" && (
        <CardActionArea>
          <Link
            to={{
              pathname: `${link}/${id}/${slugify(channel.title)}-${slugify(
                title
              )}`,
              state: { youtube_video_id: youtube_video_id }
            }}
          >
            <VideoThumbnail thumbnail={thumbnail_medium} duration={duration} />
          </Link>
        </CardActionArea>
      )}
      {isLoaded && (
        <CardContent className={classes.cardContent}>
          <GridVideoPrimary
            id={id}
            link={link}
            youtube_video_id={youtube_video_id}
            title={title}
            channel={channel}
            showTags={showTags}
            setShowTags={setShowTags}
          />
          <GridVideoSecondary
            published_at={published_at}
            channel={channel}
            videoStats={videoStats}
          />
          {showTags && <GridTagGroup tags={tags} />}
        </CardContent>
      )}
    </Card>
  );
};

export default GridVideoPreviewCard;
