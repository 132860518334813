const patchOptions = [
  {
    label: "Any",
    title: "All Patches",
    tagId: 945
  },
  {
    label: "Blackwood",
    title: "Blackwood",
    tagId: 1040
  },
  {
    label: "Flames of Ambition",
    title: "Flames of Ambition",
    tagId: 1023
  },
  {
    label: "Markarth",
    title: "Markarth",
    tagId: 986
  },
  {
    label: "Stonethorn",
    title: "Stonethorn",
    tagId: 965
  },
  {
    label: "Greymoor",
    title: "Greymoor",
    tagId: 906
  },
  {
    label: "Harrowstorm",
    title: "Harrowstorm",
    tagId: 674
  },
  {
    label: "Dragonhold",
    title: "Dragonhold",
    tagId: 676
  },
  {
    label: "Scalebreaker",
    title: "Scalebreaker",
    tagId: 678
  },
  {
    label: "Elsweyr",
    title: "Elsweyr",
    tagId: 680
  }
];

const findPatchByLabel = (label) => patchOptions.find((o) => o.label === label);

export { patchOptions, findPatchByLabel };
