const arenaOptions = [
  {
    label: "Any",
    title: "Any Arena",
    tagId: 775
  },
  {
    label: "Vateshran Hollows",
    title: "Vateshran Hollows",
    tagId: 988
  },
  {
    label: "Maelstrom Arena",
    title: "Maelstrom Arena",
    tagId: 776
  },
  {
    label: "Dragonstar Arena",
    title: "Dragonstar Arena",
    tagId: 777
  },
  {
    label: "Blackrose Prison",
    title: "Blackrose Prison",
    tagId: 778
  }
];

const findArenaByLabel = (label) => arenaOptions.find((o) => o.label === label);

export { arenaOptions, findArenaByLabel };
