export const drawerWidth = 200;

const isMobileMode = () => window.innerWidth < 600;

export const calculatePaddingLeft = () => {
  return isMobileMode() ? 0 : drawerWidth;
};

export const calculatePaddingTop = () => {
  return isMobileMode() ? 10 : 20;
};
