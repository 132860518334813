import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3)
    }
  }
}));

const SearchBar = ({ handleQueryTagChange, queryTags, tags }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        onChange={(event, value) => handleQueryTagChange(event, value)}
        id="tags-standard"
        options={tags}
        value={queryTags}
        getOptionLabel={(tag) => tag.short_path}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Find Videos!"
            fullWidth
          />
        )}
        renderOption={(tag, { inputValue }) => {
          const matches = match(tag.short_path, inputValue);
          const parts = parse(tag.short_path, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

export default SearchBar;
