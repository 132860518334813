import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "./Build.json";
export default class Like extends Component {
  //https://lottiefiles.com/web-player?lottie_url=https://assets1.lottiefiles.com/packages/lf20_znCQsa.json
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    return (
      <div className="lottie lottie-loader">
        <Lottie
          options={defaultOptions}
          height={200}
          width={300}
          isStopped={false}
          isPaused={false}
          speed={1}
        />
      </div>
    );
  }
}
