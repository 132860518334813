import React, { useState, useEffect, useContext } from "react";
import GridVideoPreviewContainer from "../GridDisplay/GridVideoPreviewContainer";
import InfiniteScroll from "react-infinite-scroller";
import appendVideo from "../utils/appendVideo";
import { StateContext } from "../StateProvider";
import Loader from "../Lotties/Loader";
import setMetadata from "../utils/metadata.js";
import { fetchResource } from "../utils/queries";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.liko.white
  }
}));

const VideoListPage = ({ title, description, link, params, metadata }) => {
  let { dispatch } = useContext(StateContext);
  const classes = useStyles();
  let location = useLocation();

  const [isFetching, setIsFetching] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loading, setLoading] = useState(false);

  const url = `videos?${queryString.stringify(params)}`;
  const appendingUrl = `videos?${queryString.stringify({
    ...params,
    page: parseInt(page) + 1
  })}`;

  const status = {
    page,
    setPage,
    hasMoreItems,
    setHasMoreItems,
    loading,
    setLoading
  };

  useEffect(() => {
    setIsFetching(true);
    const fetchAndDispatchContent = async (url) => {
      try {
        const payload = await fetchResource(url);
        dispatch({ type: "REPLACE", payload });
        setIsFetching(false);
        setIsReady(true);
      } catch (err) {
        setIsFetching(false);
      }
    };
    fetchAndDispatchContent(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const renderContent = () => (
    <InfiniteScroll
      pageStart={page}
      loadMore={() => appendVideo(appendingUrl, status, dispatch)}
      initialLoad={false}
      hasMore={hasMoreItems}
      loader={<Loader />}
    >
      <GridVideoPreviewContainer link={link} />
    </InfiniteScroll>
  );

  const whatToRender = () => {
    if (isFetching) {
      return <LoadingPage />;
    }

    if (isReady) {
      return renderContent();
    } else {
      return <ErrorPage />;
    }
  };

  return (
    <>
      {setMetadata({
        ...metadata
      })}
      <div className={classes.title}>
        <h1>{title}</h1>
        <h3>{description}</h3>
        <br />
      </div>
      {whatToRender()}
    </>
  );
};

export default VideoListPage;
