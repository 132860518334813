import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const SearchOrderSelect = ({ handleChange, options, selectedOption }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Order Content By...</InputLabel>
      <NativeSelect value={selectedOption.label} onChange={handleChange}>
        {options.map((o, i) => (
          <option value={o.label} key={i}>
            {o.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default SearchOrderSelect;
